import axios from 'axios';

const send = (data) => {
    return new Promise((resolve) => {
        axios.post('/api/sell-your-car/car-info', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(err => { })
    })
}

const SellCarService = {
    send,
}

export default SellCarService
