import React from 'react'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export const PaginationRounded = ({ productPerpege, currentPage, setCurrentPage, totalProduct }) => {

    const totalpage = Math.ceil(totalProduct / productPerpege)

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };


    return (
        <Stack spacing={2}>
            <Pagination
                count={totalpage}
                variant="outlined"
                shape="rounded"
                page={currentPage}
                onChange={handleChange}
                size="large"
                sx={{
                    '& .MuiPaginationItem-root': {
                        borderColor: '#63C089',
                        color: '#63C089',
                        '&.Mui-selected': {
                            backgroundColor: '#63C089',
                            color: '#ffffff',
                            borderColor: '#63C089',
                        },
                        '&:hover': {
                            backgroundColor: '#63C089',
                            color: '#ffffff',
                        },
                    },
                }}
            />
        </Stack>
    );
}
