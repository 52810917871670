// components
import {
    useEffect,
} from 'react'

// styles
import './Home.scss'

// components
import HomeSlider from './components/HomeSlider'
import HomeVehicles from './components/HomeVehicles'
import HomeButtons from './components/HomeButtons'
import HomeAbout from './components/HomeAbout'
import HomeServices from './components/HomeServices'
import HomeTestimonials from './components/HomeTestimonials'
import HomeBrands from './components/HomeBrands'
import HomeBanner from './components/HomeBanner'
// import HomeRecentPost from './components/HomeRecentPost'
import HomeInfo from './components/HomeInfo'
import { Seo } from '../shared/Seo/Seo'
// import { HomeBannerOffers } from './components/HomeBannerOffers'

function Home() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <section id="home-section" style={{ overflow: 'hidden' }}>
        <Seo
            title="Nowy | Compra y Venta de Seminuevos garantizados"
            description="Somos expertos en cumplir tu sueño, encuentra con nosotros: autos en venta, también vende o consigna tu actual vehículo en línea. En Nowy tenemos gran variedad de marcas de autos. En Nowy te movemos en un click."
            companyName="Nowy"
            type="website"
        />
        <HomeSlider />
        <HomeVehicles />
        <HomeButtons />
        {/* Nuevo banner */}
        {/* <HomeBannerOffers /> */}

        <HomeAbout />
        <HomeServices />
        <HomeTestimonials />
        <HomeBanner />
        <HomeBrands />
        {/* <HomeRecentPost /> */}
        <HomeInfo />
    </section>
}

export default Home