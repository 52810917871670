import axios from 'axios'

const get = (catalog) => {
    return new Promise((resolve) => {
        axios.get(`/api/${catalog}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(err => { })
    });
}

const brands = () => {
    return get('list-brands')
}

const models = (brandId) => {
    return get(`list-models/${brandId}`)
}

const CatalogsService = {
    get,
    brands,
    models,
}

export default CatalogsService
