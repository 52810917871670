import { Card, Grid } from '@mui/material';
import PinIcon from '@mui/icons-material/FmdGood';

function CarAppointment() {
    
  return <>
    <div className="container mt-10 mx-auto px-4 text-center">
      <div className="max-w-screen-sm mx-auto">
        <div className="text-4xl primary-color font-semibold">Agendar cita</div>

        <div className="mt-8">
          <div className="dates mb-6">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card className="w-100 py-4 cursor-pointer">
                  <div className="primary-color">
                    <div className="text-4xl font-bold">30</div>
                    <div className="font-semibold">Agosto</div>
                    <div className="text-gray-900 font-semibold">09:00 hrs</div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className="w-100 py-4 cursor-pointer">
                  <div className="primary-color">
                    <div className="text-4xl font-bold">31</div>
                    <div className="font-semibold">Agosto</div>
                    <div className="text-gray-900 font-semibold">13:30 hrs</div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className="w-100 py-4 cursor-pointer">
                  <div className="primary-color">
                    <div className="text-4xl font-bold">01</div>
                    <div className="font-semibold">Septiembre</div>
                    <div className="text-gray-900 font-semibold">10:30 hrs</div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
          <div className="address items-center flex justify-center">
            <PinIcon className="primary-color mr-2" /> Av. Reforma 2013, Tellería, 82149 Mazatlán, Sin.
          </div>
          <div className="map mt-2">
            <img className="w-full" alt="mapa"
              src="https://tests4geeks.com/blog/wp-content/uploads/2016/04/first-map.jpg" />
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default CarAppointment;