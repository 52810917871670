// import
import {
    Box,
    Grid,
    Rating,
} from '@mui/material'
import Carousel from 'react-material-ui-carousel'

// images
import testimonial1 from '../../../assets/images/home/testimonial/home-testimonial_1mariao_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import testimonial2 from '../../../assets/images/home/testimonial/home-testimonial_2christianv_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import testimonial3 from '../../../assets/images/home/testimonial/home-testimonial_3danielar_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'

// private const
const testimonials = [
    {
        image: testimonial1,
        alt: 'testimonial1',
        text: 'El tiempo de avalúo fue rápido, además de un seguimiento excelente de Nowy',
        rating: 5,
    },
    {
        image: testimonial2,
        alt: 'testimonial2',
        text: 'Aún no me decido por venderlo, pero la atención recibida ha sido muy buena',
        rating: 4,
    },
    {
        image: testimonial3,
        alt: 'testimonial3',
        text: 'No pensé lograr vender mi carro tan rápido. Además la oferta fue muy buena',
        rating: 5,
    },
]

// component
function SellCarTestimonials() {
    const renderTestimonials = (quantity) => {
        const componenets = []
        const containers = []
        let items = []

        for (const testimonial of testimonials) {
            items.push(testimonial)

            if (items.length === quantity) {
                containers.push(items)
                items = []
            }
        }

        if (items.length > 0 && items.length < quantity) {
            containers.push(items)
        }

        for (const containerIndex in containers) {
            const container = containers[containerIndex]
            const gridItems = []

            for (const itemIndex in container) {
                const testimonial = container[itemIndex]
                const gridItem = <Grid item 
                    key={`testimonial_${containerIndex}_${itemIndex}`}
                    xs={12/quantity}
                    className='flex justify-center'
                >
                    <Box className='w-[240px]  h-[420px]'>
                        <Box className='w-[140px] h-[140px] relative ml-[50px] rounded-[70px] bg-center bg-cover z-10'
                            sx={{
                                backgroundImage: `url(${testimonial.image})`,
                            }}
                        />

                        <Box className='bg-white pt-[50px] pb-[20px] rounded-2xl relative mt-[-50px]'>
                            <p className='font-regular text-center p-[25px]'>
                                {testimonial.text}
                            </p>

                            <Box className='grid place-items-center'>
                                <Rating readOnly
                                    value={testimonial.rating}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                gridItems.push(gridItem)
            }

            componenets.push(<Grid container
                key={`testimonial_container_${containerIndex}`}
                spacing={2}
                alignItems='center'
                justifyContent='center'
            >
                {gridItems}
            </Grid>)
        }

        return componenets
    }

    // render component
    return <>
        <Box className="container p-[20px]">
        <Box sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                }
            }}>
                <Carousel 
                    navButtonsAlwaysVisible
                    animation='slide'
                    duration='900'
                >
                    {renderTestimonials(3)}
                </Carousel>
            </Box>
            
            <Box sx={{
                display: {
                    xs: 'block',
                    md: 'none',
                }
            }}>
                <Carousel
                    navButtonsAlwaysVisible
                    animation='slide'
                    duration='900'
                >
                    {renderTestimonials(1)}
                </Carousel>
            </Box>
        </Box>
    </>
}

export default SellCarTestimonials
