import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, CircularProgress, FormControl, TextField } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const defaultValues = {
    id: '',
    name: ''
};

const schema = yup.object().shape({
    mode: yup.string().required(''),
    phone: yup.string().required('')
});

function CarTouch() {
    const [loading, setLoading] = useState(false);
    const { formState, control } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const { isValid, dirtyFields } = formState;

    const optionsMode = ['Whatsapp', 'Celular'];

    function isValidForm() {
        return !_.isEmpty(dirtyFields) || isValid;
    }

    function handleClickSubmit(e) {
        e.preventDefault();
    }

    function submit() {
        setLoading(true);
    }

    function _onSubmit() {
        if (!loading) {
            submit();
        }
    }

    function _isValidForm() {
        return _.isFunction(isValidForm) ? (isValidForm() && !loading) : false;
    }

    return <>
        <div className="container mt-10 mx-auto px-4 text-center">
            <div className="max-w-screen-sm mx-auto">
                <div className="text-4xl primary-color font-semibold">Me interesa el Auto</div>

                <div className="mt-8">
                    <form onSubmit={handleClickSubmit}>
                        <FormControl required fullWidth>
                            <Controller
                                name="mode"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        fullWidth
                                        className="mb-8"
                                        options={optionsMode}
                                        onChange={(event, newValue) => {
                                            // setValue(newValue);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            // setInputValue(newInputValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="¿Cómo deseas ser contactado?" />}
                                    />
                                )
                                }
                            />
                        </FormControl>

                        <FormControl className="mb-8" required fullWidth>
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="¿Cómo deseas ser contactado?"
                                        // error={!!errors.name}
                                        // helperText={errors?.name?.message}
                                        required
                                        variant="outlined"
                                    />
                                )}
                            />
                        </FormControl>
                    </form>

                    <div className="py-8">
                        Al hacer clic en "Continuar" confirmo que he leído y acepto los Términos y Condiciones y Aviso de Privacidad de Nowy.
                    </div>

                    <div className="text-center mb-10">
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="w-full">
                            <Box sx={{ m: 1, position: 'relative' }} className="w-full">
                                <Button
                                    variant="contained"
                                    className="mx-auto w-full btn-primary"
                                    disabled={!_isValidForm()}
                                    type="submit"
                                    onClick={_onSubmit}
                                >
                                    CONTINUAR
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        color='secondary'
                                        sx={{
                                            color: '#fff',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px'
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>

                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default CarTouch;