import LazyLoad from 'react-lazyload'

export const Image = ({image, alt, style = {}, className = ''}) => {
  return (
    <LazyLoad className={className}>       
        <img
            style={style}
            src={image}
            alt={alt}
            loading='lazy'
        />
    </LazyLoad>
  )
}
