import { useEffect } from 'react'

export const PreloadImages = ({ images }) => {
    useEffect(() => {
        const links = images.map(({ image }) => {
          const link = document.createElement('link');
          link.rel = 'preload';
          link.href = image;
          link.as = 'image';
          document.head.appendChild(link);
          return link;
        });
    
        return () => {
          links.forEach(link => document.head.removeChild(link));
        };
      }, [images]);
    
      return null;
}
