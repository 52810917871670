// import
import {
    useState,
    useEffect,
} from 'react'
import validator from 'validator'

// services
import catalogsService from '../../services/catalogsService'
import consignCarService from '../../services/consignCarService'

// styles
import './ConsignCar.scss'

// custom components
import Form from './components/Form.jsx'
import Contact from './components/Contact.jsx'
import Alert from '../shared/Alert/Alert.jsx'

// images
import logo from '../../assets/images/logos/logo.png'
import { Seo } from '../shared/Seo/Seo.jsx'

// private props
const contactNumber = '+52 66 9383 0570'
const zoomUrl = 'https://us06web.zoom.us/j/84291532904?pwd=VGtyaytkZWZZS3hNWklDakZqWmwwdz09'

// private functions
const sortCatalog = (catalog) => {
    return catalog.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0;
    })
}

// component
function ConsignCar() {

    // state
    const [template, setTemplate] = useState('form') // form, contact
    const [brands, setBrands] = useState([])
    const [models, setModels] = useState([])
    const [brand, setBrand] = useState({ id: 0, name: 'Marca' })
    const [brandError, setBrandError] = useState('')
    const [model, setModel] = useState({ id: 0, name: 'Modelo' })
    const [modelError, setModelError] = useState('')
    const [budget, setBudget] = useState('')
    const [budgetError, setBudgetError] = useState('')
    const [activity, setActivity] = useState('empty')
    const [activityError, setActivityError] = useState('')
    const [credit, setCredit] = useState('')
    const [creditError, setCreditError] = useState('')
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [lastName, setLastName] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [loadingForm, setLoadingForm] = useState(false)
    const [alert, setAlert] = useState({ show: false })

    // effects
    useEffect(() => {
        // request brands
        catalogsService.brands().then(brands_array => {
            let sorted_brands = sortCatalog(brands_array)
            setBrands(sorted_brands)
        })

        // scroll to top
        window.scrollTo(0, 0)
        setTemplate('form')
    }, [])

    // scroll on template consign
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [template])

    // request models when brand consign
    useEffect(() => {
        setModel({
            id: 0,
            name: 'Modelo'
        })

        if (brand.id === 0) {
            setModels([])
        }
        else {
            catalogsService.models(brand.id).then(models_array => {
                let sorted_models = sortCatalog(models_array)
                setModels(sorted_models)
            })
        }
    }, [brand])

    // public functions
    const handleFormChange = (field, value) => {
        switch (field) {
            case 'brand':
                setBrand(value)
                setBrandError('')
                break

            case 'model':
                setModel(value)
                setModelError('')
                break

            case 'budget':
                setBudget(value)
                setBudgetError('')
                break

            case 'activity':
                setActivity(value)
                setActivityError('')
                break

            case 'credit':
                setCredit(value)
                setCreditError('')
                break

            case 'name':
                setName(value)
                setNameError('')
                break

            case 'lastName':
                setLastName(value)
                setLastNameError('')
                break

            case 'phone':
                setPhone(value)
                setPhoneError('')
                break

            case 'email':
                setEmail(value)
                setEmailError('')
                break

            default:
                console.log(`Field ${field} unrecognized`)
        }
    }

    const continueForm = async () => {
        let error = false
        setLoadingForm(true)

        if (brand.id === 0) {
            setBrandError('Seleccione la Marca de su Auto para continuar')
            error = true
        }

        if (model.id === 0) {
            setModelError('Seleccione el Modelo de su Auto para continuar')
            error = true
        }

        if (budget === '' || budget <= 0) {
            setBudgetError('Ingrese el Presupuesto para realizar el cambio')
            error = true
        }

        if (activity === 'empty') {
            setActivityError('Ingrese el tipo de actividad de sy vehiculo')
            error = true
        }

        if (credit.trim() === '' || budget <= 0) {
            setCreditError('Ingrese el el monto deseado por su Automovil')
            error = true
        }

        if (name.trim() === '') {
            setNameError('Ingrese su nombre para continuar')
            error = true
        }

        if (lastName.trim() === '') {
            setLastNameError('Ingrese su apellido para continuar')
            error = true
        }


        const PHONE_REGEX = new RegExp(/^[0-9]+$/);
        if (!PHONE_REGEX.test(phone)) {
            setPhoneError('Introduzca un número válido para continuar')
            error = true
        }
        if (phone.trim() === '') {
            setPhoneError('Ingrese su teléfono para continuar')
            error = true
        }

        if (email.trim() === '') {
            setEmailError('Ingrese su email para continuar')
            error = true
        }
        else if (!validator.isEmail(email.trim())) {
            setEmailError('Introduzca un correo válido para continuar')
            error = true
        }

        // send request
        if (!error) {
            let data = {
                budget,
                activity,
                credit,
                name,
                phone,
                email,
                brand_id: brand.id,
                model_id: model.id,
                last_name: lastName,
            }

            let response = await consignCarService.send(data)
            if (response.message !== 'Created successfully') {
                setAlert({
                    show: true,
                    message: 'No se ha completado el envio de su información, por favor intente nuevamente',
                    actionText: 'Aceptar',
                    onAction: () => {
                        setAlert({
                            show: false
                        })
                    }
                })
                error = true
            }
        }

        if (!error) {
            setTemplate('contact')
        }

        setLoadingForm(false)
    }

    const buildWhatsappData = () => {
        let contact_number = contactNumber.replace('+', '').replaceAll(' ', '')

        let message = `Hola, soy ${name} ${lastName}. Me interesa cambiar mi Auto ${brand.name} ${model.name}\n\n`
        message += 'Mi información es la siguiente...\n'
        message += `Marca: ${brand.name}\n`
        message += `Modelo: ${model.name}\n`
        message += `Presupuesto: ${budget}\n`
        message += `Actividad: ${activity}\n`
        message += `Monto Deseado: ${credit}\n`
        message += `Nombre del propieratio en la Factura: ${name}\n`
        message += `Ubicación del Vehiculo: ${lastName}\n`
        message += `Teléfono: ${phone}\n`
        message += `Email: ${email}`

        message = encodeURIComponent(message)

        return `https://wa.me/${contact_number}?text=${message}`
    }

    // render component
    return <>
        <Seo
            title="Nowy | Vende tu carro usado"
            description="Ofrecemos servicios de consignación de autos para que vendas tu carro usado sin complicaciones. Deja que nuestros expertos manejen todo el proceso por ti. En Nowy atendemos todas las marcas de autos."
            companyName="Nowy"
            type="website"
        />
        <section id="sell-car">
            <div id="sell-car-header">
                <div className="mt-12 mb-12 flex justify-center">
                    <img
                        src={logo}
                        alt="Logo Nowy"
                        width="200px"
                    />
                </div>
            </div>

            {template === 'form' ?
                <Form
                    brands={brands}
                    brand={brand}
                    brandError={brandError}
                    models={models}
                    model={model}
                    modelError={modelError}
                    budget={budget}
                    budgetError={budgetError}
                    activity={activity}
                    activityError={activityError}
                    credit={credit}
                    creditError={creditError}
                    name={name}
                    nameError={nameError}
                    lastName={lastName}
                    lastNameError={lastNameError}
                    phone={phone}
                    phoneError={phoneError}
                    email={email}
                    emailError={emailError}
                    loading={loadingForm}
                    onChange={handleFormChange}
                    continueAction={continueForm}
                />
                : null}

            {template === 'contact' ?
                <Contact
                    brand={brand}
                    model={model}
                    budget={budget}
                    activity={activity}
                    credit={credit}
                    name={name}
                    lastName={lastName}
                    phone={phone}
                    email={email}
                    whatsappUrl={buildWhatsappData()}
                    zoomUrl={zoomUrl}
                />
                : null}
        </section>

        <Alert
            show={alert.show}
            message={alert.message}
            actionText={alert.actionText}
            onAction={alert.onAction}
            onClose={() => {
                setAlert({
                    show: false
                })
            }}
        />
    </>
}

export default ConsignCar