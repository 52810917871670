import React, { Suspense, lazy } from 'react';
import { Box, Grid } from '@mui/material';


// Slider images
import testimonial1 from '../../../assets/images/home/testimonial/home-testimonial_1mariao_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp';
import testimonial2 from '../../../assets/images/home/testimonial/home-testimonial_2christianv_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp';
import testimonial3 from '../../../assets/images/home/testimonial/home-testimonial_3danielar_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp';
import testimonial4 from '../../../assets/images/home/testimonial/home-testimonial_4juang_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp';
import testimonial5 from '../../../assets/images/home/testimonial/home-testimonial_5lupitan_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp';
import { PreloadImages } from '../../shared/PreloadImages/PreloadImages';
import LazyLoad from 'react-lazyload'

// Testimonial data
const testimonials = [
    {
        image: testimonial1,
        alt: 'home-testimonial_1mariao_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        title: 'MARÍA OSUNA',
        desc: 'Me sentí muy segura en cada parte del proceso ¡pero sobre todo estoy muy contenta en mi seminuevo!',
    },
    {
        image: testimonial2,
        alt: 'home-testimonial_2christianv_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        title: 'CHRISTIAN VELAZQUEZ',
        desc: 'Encontré autos seminuevos como si fueran casi nuevos ¡a precios excelentes! Estoy por estrenar',
    },
    {
        image: testimonial3,
        alt: 'home-testimonial_3danielar_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        title: 'DANIELA ROSAS',
        desc: 'Tenía meses indecisa por vender o no mi auto hasta que llegué aquí. Hoy en día conduzco uno mejor ¡Gracias Nowy!',
    },
    {
        image: testimonial4,
        alt: 'home-testimonial_4juang_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        title: 'JUAN GONZÁLEZ',
        desc: 'Yo solo venía a curiosear en la página y terminé consignando mi vehículo. Me ahorraron mucho tiempo ¡excelente!',
    },
    {
        image: testimonial5,
        alt: 'home-testimonial_5lupitan_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        title: 'LUPITA NIETO',
        desc: 'Usualmente no me siento cómoda en compras en línea, pero Nowy me ha facilitado la vida. Excelente trato y sencillos los procesos',
    },
];

const Carousel = lazy(() => import('react-material-ui-carousel'));

// Component
function HomeTestimonials() {
    const renderTestimonials = (quantity) => {
        const components = [];
        const containers = [];
        let items = [];


        for (const testimonial of testimonials) {
            items.push(testimonial);

            if (items.length === quantity) {
                containers.push(items);
                items = [];
            }
        }

        if (items.length > 0 && items.length < quantity) {
            containers.push(items);
        }

        for (const [containerIndex, container] of containers.entries()) {
            const gridItems = container.map((testimonial, itemIndex) => (
                <Grid item key={`testimonial_${containerIndex}_${itemIndex}`} xs={12 / quantity} className='flex justify-center'>
                    <Box className='w-[240px] h-[420px]'>
                        <Box className='w-[140px] h-[140px] relative ml-[50px] rounded-[70px] bg-center bg-cover z-10'
                            sx={{
                                backgroundImage: `url(${testimonial.image})`,
                            }}
                        />

                        <Box className='bg-white pt-[50px] pb-[20px] rounded-2xl relative mt-[-50px]'>
                            <p className='font-subtitle text-xl text-center py-[20px]'>{testimonial.title}</p>
                            <p className='font-regular text-center p-[25px]'>{testimonial.desc}</p>
                        </Box>
                    </Box>
                </Grid>
            ));

            components.push(
                <Grid container key={`testimonial_container_${containerIndex}`} spacing={2} alignItems='center' justifyContent='center'>
                    {gridItems}
                </Grid>
            );
        }

        return components;
    };

    return (
        <Box className="container-fluid" sx={{ backgroundColor: '#f7f7f7' }}>
            <PreloadImages images={testimonials} />
            <Box className="container p-[20px]">
                <Grid container spacing={2} alignItems='center' justifyContent='center' className='p-[20px]'>
                    <h4 className='font-title text-4xl text-center mb-[20px] px-[20px]'>Testimoniales</h4>
                </Grid>
                <LazyLoad>
                <Suspense >
                    {/* Different Carousels based on screen size */}
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Carousel navButtonsAlwaysVisible animation='slide' duration={900}>
                            {renderTestimonials(3)}
                        </Carousel>
                    </Box>

                    <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
                        <Carousel navButtonsAlwaysVisible animation='slide' duration={900}>
                            {renderTestimonials(2)}
                        </Carousel>
                    </Box>

                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <Carousel navButtonsAlwaysVisible animation='slide' duration={900}>
                            {renderTestimonials(1)}
                        </Carousel>
                    </Box>
                </Suspense>
                </LazyLoad>
            </Box>
        </Box>
    );
}

export default HomeTestimonials;