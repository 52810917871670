import { Card, CardContent, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import CarCover from "../../shared/Car/CarCover/CarCover";
import './CarCard.scss';

// svg components
import Certificate from '../../svg/Certificate'

function CarCard({ car }) {
    const numberFormat = (number) => number.toLocaleString('es-MX');
    const [cover, setCover] = useState('');

    useEffect(() => {
        const getCover = () => {
            const imgs = car.media.filter(media => media.media_file_type === 'IMAGE');

            if (!!imgs.length) {
                return `${process.env.REACT_APP_MEDIA_HOST}${imgs[0].media_file_url}`;
            } else {
                return '';
            }
        }

        if (!!car.media.length) {
            setCover(getCover());
        }
    }, [car, setCover]);

    return <>
        <Card
            className="w-100 cursor-pointer car-card mb-2"
            sx={{
                boxShadow: "0px 0px 12px 0px rgba(23,23,23,0.2)"
            }}
        >
            <CarCover
                km={car?.km}
                image={cover}
                status={car.vehicle_transaction_status || 'PENDIENTE'}
            />
            <CardContent sx={{ paddingTop: '5px', paddingBottom: '10px !important' }} className="text-gray-800 font-semibold">
                <div className="flex place-items-center justify-center mt-2">
                    {car.inventory === 'FISICO' ? <div className="mr-2">
                        <Certificate
                            width="18"
                        />
                    </div> : <div className="mt-8" />}

                    <span className="text-lg leading-3">
                        <b>{car.model?.brand?.name}</b> {car.model?.name}
                    </span>
                </div>
                <div className="text-lg text-center">
                    {car.year} / {numberFormat(+car.km)} km
                </div>
                <div className="grid grid-cols-2 place-items-center mt-1">
                    <div className="price mr-2">
                        ${numberFormat(+car.price)}
                    </div>
                    <div>
                        {Number(car.monthly_installment) > 0 ? <Chip
                            className="monthly ml-1 mr-1" 
                            label={`Desde $${numberFormat(+car.monthly_installment)} / Mes`}
                        /> : null}
                    </div>
                </div>
            </CardContent>
        </Card>
    </>;
}

export default CarCard;