import { Chip } from "@mui/material";
import defaultImg from '../../../../assets/images/car-default.png';
import './CarCover.scss';

function CarCover({ image, status, km }) {

    return (
        <>
            {/* <div style={{position:'relative', top:0, left:0, textTransform:'uppercase', color:'white', paddingLeft:'10px', background: 'linear-gradient(90deg, rgba(2,69,79,1) 0%, rgba(0,164,189,0.85) 49%, rgba(0,114,138,0) 100%)'}}>nuevo</div> */}
            {
                km <= 1000
                    ? <div style={{ position: 'relative', top: 0, left: 0, textTransform: 'uppercase', textAlign: 'center', color: 'white', padding: '8px', fontWeight: 'bold', fontSize: 20, background: 'linear-gradient(90deg, rgba(11,6,96,1) 0%, rgba(9,134,166,0.7847514005602241) 60%, rgba(0,114,138,0.15449929971988796) 100%)' }}>nuevo</div>
                    : <div style={{ position: 'relative', top: 0, left: 0, textTransform: 'uppercase', textAlign: 'center', color: 'white', padding: '8px', fontWeight: 'bold', fontSize: 20, background: 'linear-gradient(90deg, rgba(0,100,80,1) 0%, rgba(149,225,191,1) 80%, rgba(149,225,191,0.4458158263305322) 99%)' }}>semi nuevo</div>
            }

            <div
                className="w-full h-[250px] bg-cover bg-center car-cover"
                style={{ backgroundImage: `url(${!image ? defaultImg : image})` }}
            >
                <Chip
                    className="car-status"
                    label={status}
                />
            </div>
        </>
    )
}

export default CarCover;