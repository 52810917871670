// helpers
const sortCatalog = (catalog) => {
    return catalog.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    })
}


// export
const CatalogsHelpers = {
    sortCatalog,
}

export default CatalogsHelpers
