// import
import {
    useState,
    useEffect,
} from 'react'
import validator from 'validator'
import moment from 'moment'

// services
import carService from '../../services/carService'

// custom components
import Alert from '../shared/Alert/Alert.jsx'
import Selection from './components/Selection.jsx'
import Form from './components/Form.jsx'
import Appointment from './components/Appointment.jsx'
import Contact from './components/Contact.jsx'

// images
import logo from '../../assets/images/logos/logo.png'

// styles
import './Services.scss'
import { Seo } from '../shared/Seo/Seo.jsx'

// private props
const creditoNumber = '+52 66 9383 0574'
const valuaNumber = '+52 66 9383 0570'
const diagnosticoNumber = '+52 66 9383 0570'
const seguroNumber = '+52 66 9383 0572'
const zoomUrl = 'https://us06web.zoom.us/j/84291532904?pwd=VGtyaytkZWZZS3hNWklDakZqWmwwdz09'

// component
function Services() {

    // state
    const [template, setTemplate] = useState('selection') // selection, form, contact
    const [serviceSelected, setServiceSelected] = useState(null)
    const [serviceValueSelected, setServiceValueSelected] = useState(null)
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [lastName, setLastName] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [loadingForm, setLoadingForm] = useState(false)
    const [appointment, setAppointment] = useState(null)
    const [appointmentError, setAppointmentError] = useState('')
    const [loadingAppointment, setLoadingAppointment] = useState(false)
    const [alert, setAlert] = useState({
        show: false
    })

    // effects
    useEffect(() => {
        // scroll to top
        window.scrollTo(0, 0)
    }, [])

    // scroll on template change
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [template])

    // public functions
    const continueSelection = () => {
        if (serviceSelected === null) {
            setAlert({
                show: true,
                message: 'Por favor seleccione uno de nuestros servicios para continuar',
                actionText: 'Aceptar',
                onAction: () => {
                    setAlert({
                        show: false
                    })
                }
            })
            return
        }

        setTemplate('form')
    }

    const handleFormChange = (field, value) => {
        if (field === 'name') {
            setName(value)
            setNameError('')
        }
        else if (field === 'last_name') {
            setLastName(value)
            setLastNameError('')
        }
        else if (field === 'phone') {
            setPhone(value)
            setPhoneError('')
        }
        else if (field === 'email') {
            setEmail(value)
            setEmailError('')
        }
    }

    const continueForm = async () => {
        let error = false
        setLoadingForm(true)

        if (name.trim() === '') {
            setNameError('Introduzca su nombre para continuar')
            error = true
        }

        if (lastName.trim() === '') {
            setLastNameError('Introduzca su apellido para continuar')
            error = true
        }

        if (phone.trim() === '') {
            setPhoneError('Introduzca su número de teléfono para continuar')
            error = true
        }
        if (email.trim() === '') {
            setEmailError('Introduzca su correo para continuar')
            error = true
        }
        else if (!validator.isEmail(email.trim())) {
            setEmailError('Introduzca un correo válido para continuar')
            error = true
        }

        if (!error) {
            setAppointment(null)
            setAppointmentError('')
            setTemplate('appointment')
        }

        setLoadingForm(false)
    }

    // appointment functions
    const buildDates = () => {
        const dates = []

        moment.updateLocale('es', {
            months: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
            ]
        })

        const dateOne = moment().add(1, 'days')
        const dayOne = {
            value: {
                date: dateOne.format('YYYY-MM-DD'),
                time: null,
            },
            month: dateOne.locale('es').format('MMMM'),
            day: dateOne.locale('es').format('DD'),
        }
        dates.push(dayOne)

        const dateTwo = moment().add(2, 'days')
        const dayTwo = {
            value: {
                date: dateTwo.format('YYYY-MM-DD'),
                time: null,
            },
            month: dateTwo.locale('es').format('MMMM'),
            day: dateTwo.locale('es').format('DD'),
        }
        dates.push(dayTwo)

        const dateThree = moment().add(3, 'days')
        const dayThree = {
            value: {
                date: dateThree.format('YYYY-MM-DD'),
                time: null,
            },
            month: dateThree.locale('es').format('MMMM'),
            day: dateThree.locale('es').format('DD'),
        }
        dates.push(dayThree)

        return dates
    }

    const handleAppointment = (date) => {
        setAppointment(date)
        setAppointmentError('')
    }

    const continueAppointment = async () => {
        setLoadingAppointment(true)

        let error = false

        if (appointment === '') {
            error = true
            setAppointmentError('Seleccione la fecha de su preferencia')
        }

        // send request
        if (!error) {
            let data = {
                name,
                phone,
                email,
                date: appointment.date,
                // time: appointment.time,
                service: serviceSelected,
                last_name: lastName,
            }

            let response = await carService.send(data)
            if (response.message !== 'Created successfully') {
                setAlert({
                    show: true,
                    message: 'No se ha completado el envio de su información, por favor intente nuevamente',
                    actionText: 'Aceptar',
                    onAction: () => {
                        setAlert({
                            show: false
                        })
                    }
                })
                error = true
            }
        }

        if (!error) {
            setTemplate('contact')
        }

        setLoadingAppointment(false)
    }

    // contact functions
    const buildWhatsappData = () => {
        let message = `Hola, soy ${name.trim()} ${lastName.trim()}, me gustaría conocer información sobre el servicio de ${serviceSelected}`
        message = encodeURIComponent(message)

        let contactNumber = ''

        switch (serviceValueSelected) {
            case 'crédito':
                contactNumber = creditoNumber
                break

            case 'valua':
                contactNumber = valuaNumber
                break

            case 'diagnostico':
                contactNumber = diagnosticoNumber
                break

            case 'seguro':
                contactNumber = seguroNumber
                break

            default: {
                contactNumber = valuaNumber
            }
        }
        contactNumber = contactNumber.replace('+', '').replaceAll(' ', '')

        return `https://wa.me/${contactNumber}?text=${message}`
    }

    // render component
    return <>
        <Seo
            title="Nowy | Servicios Automotrices de calidad. Mantenimiento y reparación"
            description="Brindamos servicios automotrices completos: mantenimiento, reparación y más para todas las marcas de autos. Confía en nuestros expertos para cuidar tu vehículo en México."
            companyName="Nowy"
            type="website"
        />
        <section id="services">
            <div id="services-header">
                <div className="mt-12 mb-12 flex justify-center">
                    <img
                        src={logo}
                        alt="Logo Nowy"
                        width="200px"
                    />
                </div>
            </div>

            {template === 'selection' ? <Selection
                serviceSelected={serviceSelected}
                onSelection={(service, value) => {
                    setServiceSelected(service)
                    setServiceValueSelected(value)
                }}
                continueAction={() => {
                    continueSelection()
                }}
            /> : null}

            {template === 'form' ? <Form
                name={name}
                nameError={nameError}
                lastName={lastName}
                lastNameError={lastNameError}
                phone={phone}
                phoneError={phoneError}
                email={email}
                emailError={emailError}
                loading={loadingForm}
                onChange={handleFormChange}
                backAction={() => {
                    setTemplate('selection')
                }}
                continueAction={() => {
                    continueForm()
                }}
            /> : null}

            {template === 'appointment' ? <Appointment
                dates={buildDates()}
                appointment={appointment}
                appointmentError={appointmentError}
                loading={loadingAppointment}
                onSelectDate={handleAppointment}
                backAction={() => {
                    setTemplate('form')
                }}
                continueAction={() => {
                    continueAppointment()
                }}
            /> : null}

            {template === 'contact' ? <Contact
                whatsappUrl={buildWhatsappData()}
                name={name}
                lastName={lastName}
                phone={phone}
                email={email}
                appointment={appointment}
                zoomUrl={zoomUrl}
            /> : null}
        </section>

        <Alert
            show={alert.show}
            message={alert.message}
            actionText={alert.actionText}
            onAction={alert.onAction}
            onClose={() => {
                setAlert({
                    show: false
                })
            }}
        />
    </>
}

export default Services