import { Skeleton } from "@mui/material"

export const LoadingSkeletonCarDetail = () => {
    return (
        <>
            <Skeleton className="mb-2" variant="rectangular" height={30} />
            <Skeleton className="mb-8" variant="rectangular" height={30} width={50} />

            <Skeleton className="mb-5" variant="rectangular" height={35} />
            <Skeleton className="mb-10" variant="rectangular" height={35} />

            <Skeleton className="mb-5" variant="rectangular" height={80} width={300} />
        </>
    )
}
