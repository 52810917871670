import axios from 'axios';

const send = (data) => {
    return new Promise((resolve) => {
        axios.post('api/change-your-car/contact', data)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => { })
    })
}

const ConsignCarService = {
    send,
}

export default ConsignCarService