// components
import { Link as RouterLink } from 'react-router-dom'
import {
    Box,
    Grid,
    Link,
} from '@mui/material'
import {styled} from '@mui/material/styles'


const LinkButton = styled(Link)({
    width: '180px',
    padding: '10px',
    margin:'10px',
    backgroundColor:'#00CE7C',
    textAlign: 'center',
    color: 'black',
    fontFamily: 'Poppins-Bold',
    fontSize: 18,
    borderRadius: 20 
});

// component
function HomeButtons() {
    return <Box className="container-fluid"
        sx={{
            backgroundColor: '#f7f7f7',
        }}
    >
        <Box className="container"
            sx={{
                paddingY: '20px',
                paddingX: '20px',
            }}
        >
            <h4 className='font-title text-3xl text-center mb-[20px]'>
                Vive la experiencia
            </h4>

            <h6 className='font-subtitle text-xl text-center mb-[20px]'>
                TODOS NUESTROS AUTOS SEMINUEVOS ESTÁN CERTIFICADOS Y CON GARANTÍA
            </h6>

            <Box sx={{
                paddingY: '40px',
            }}>
                <Grid container
                    spacing={2}
                    direction={{
                        xs: 'column',
                        md: 'row',
                    }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <LinkButton
                        underline='none'
                        component={RouterLink}
                        to='/compra-tu-auto'
                    >
                        Compra
                    </LinkButton>
                    <LinkButton
                        underline='none'
                        component={RouterLink}
                        to='/vende-tu-auto'
                    >
                        Venta
                    </LinkButton>
                    <LinkButton
                        underline='none'
                        component={RouterLink}
                        to='/consigna-tu-auto'
                    >
                        Consigna
                    </LinkButton>

                   
                </Grid>
            </Box>
        </Box>
    </Box>
}

// export
export default HomeButtons
