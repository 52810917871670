// import
import {
    Card,
    CardContent,
    FormHelperText,
    Typography,
    Input,
    Button,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import SyncIcon from '@mui/icons-material/Sync'

// styles
import '../Services.scss'

// component
function Form(props) {

    // render component
    return <>
        <div className="services-content">
            <div className="flex justify-center">
                <Card className="services-card py-4">
                    <CardContent>
                        <Typography className="card-title text-center">
                            Agendar una Cita con los Expertos
                        </Typography>

                        <Typography className="card-subtitle text-center">
                            Para servirte mejor, proporciónanos tus datos de contacto
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            <div className="flex justify-center mt-6 mb-4">
                <Card className="services-list">
                    <CardContent>
                        <p className="font-bold text-lg text-center pb-4">
                            Registrate para Continuar
                        </p>

                        <div className="p-2">
                            <Input
                                fullWidth
                                disableUnderline
                                className="px-2 py-1 bg-slate-200 rounded-md"
                                value={props.name}
                                placeholder="Nombre"
                                name="name"
                                onChange={(event) => {
                                    props.onChange('name', event.target.value)
                                }}
                            />
                            <FormHelperText className="error-label">{props.nameError}</FormHelperText>
                        </div>

                        <div className="p-2">
                            <Input
                                fullWidth
                                disableUnderline
                                className="px-2 py-1 bg-slate-200 rounded-md"
                                value={props.last_name}
                                placeholder="Apellido"
                                name="last_name"
                                onChange={(event) => {
                                    props.onChange('last_name', event.target.value)
                                }}
                            />
                            <FormHelperText className="error-label">{props.lastNameError}</FormHelperText>
                        </div>

                        <div className="p-2">
                            <Input
                                fullWidth
                                disableUnderline
                                className="px-2 py-1 bg-slate-200 rounded-md"
                                value={props.phone}
                                placeholder="Teléfono"
                                name="phone"
                                type="tel"
                                onChange={(event) => {
                                    props.onChange('phone', event.target.value)
                                }}
                            />
                            <FormHelperText className="error-label">{props.phoneError}</FormHelperText>
                        </div>

                        <div className="p-2">
                            <Input
                                fullWidth
                                disableUnderline
                                className="px-2 py-1 bg-slate-200 rounded-md"
                                value={props.email}
                                placeholder="Email"
                                name="email"
                                type="email"
                                onChange={(event) => {
                                    props.onChange('email', event.target.value)
                                }}
                            />
                            <FormHelperText className="error-label">{props.emailError}</FormHelperText>
                        </div>

                    </CardContent>
                </Card>
            </div>

            <div className="flex justify-center">
                <Button
                    disabled={props.loading}
                    variant="contained"
                    className="service-button back-button"
                    onClick={() => {
                        if (props.backAction) {
                            props.backAction()
                        }
                    }}
                >
                    REGRESAR
                </Button>
            </div>

            <div className="flex justify-center">
                <Button
                    disabled={props.loading}
                    variant="contained"
                    className="service-button"
                    endIcon={props.loading ? <SyncIcon /> : <SendIcon />}
                    onClick={() => {
                        if (props.continueAction) {
                            props.continueAction()
                        }
                    }}
                >
                    {props.loading ? 'ENVIANDO' : 'CONTINUAR'}
                </Button>
            </div>
        </div>
    </>;
}

export default Form;