// import
import {
    Button,
    Card,
    CardContent,
} from '@mui/material'
import { SocialIcon } from 'react-social-icons'

// styles
import '../ConsignCar.scss'

// resources
import zoom from '../../../assets/images/zoom/zoom.png'

// component
function Contact(props) {

    // render component
    return <>
        <div className="sell-car-content">
            <div className="flex justify-center mt-6 mb-4">
                <Card className="sell-car-list">
                    <CardContent className="pb-10">
                        <p className="py-2 text-md">
                            Usted es muy importante para nosotros en breve nuestro teleasesor se pondrá en contacto.
                        </p>
                        <p className="py-2 text-md">
                            Realizaremos una revisión de su automovil en forma remota y le contactaremos, si usted acepta nuestra propuesta de CONSIGNACION podrá agendar una cita de inspección, nos entregara copia de los papeles del vehículo y por 30 días será parte de nuestra red de concesionarios para buscar un comprador de su automovil.
                        </p>
                        <p className="py-2 text-md">
                            En el momento de la compra deberá contar con todos los papeles en orden para traspasar al comprador el vehículo.
                        </p>
                        <p className="py-2 mb-10 text-md">
                            Haremos la programación de una cita con el comprador en nuestras instalaciones designadas. O bien si lo desea puede iniciar una conversación AHORA MISMO con nuestro centro de atención a clientes o llamar al movil 6693830570.
                        </p>

                        <div className="flex justify-center py-4 mb-2">
                            <SocialIcon
                                url={props.whatsappUrl}
                                network="whatsapp"
                                fgColor="#fff"
                                style={{
                                    height: 60,
                                    width: 60,
                                }}
                                target="_blank"
                            />

                            <a href={props.zoomUrl} className="ml-4" target="_blank" rel="noreferrer">
                                <img src={zoom} width='60' alt='zoom' />
                            </a>
                        </div>

                        <Button
                            variant="contained"
                            className="h-full w-full btn-indigo"
                            onClick={() => {
                                window.open('https://zoom.us/download')
                            }}
                        >
                            No tienes software de zoom, descarguelo aquí
                        </Button>
                        
                        <div className="mt-2 p-2 border">
                            <p className="py-2 text-md succes-text">
                                <strong>Datos enviados</strong>
                            </p>

                            <p>
                                <strong>Marca: </strong>
                                <span>{props.brand.name}</span>
                            </p>

                            <p>
                                <strong>Modelo: </strong>
                                <span>{props.model.name}</span>
                            </p>

                            <p>
                                <strong>Presupuesto: </strong>
                                <span>{props.budget}</span>
                            </p>

                            <p>
                                <strong>Actividad: </strong>
                                <span>{props.activity}</span>
                            </p>

                            <p>
                                <strong>Monto Deseado: </strong>
                                <span>{props.credit}</span>
                            </p>

                            <p>
                                <strong>Nombre del propieratio en la Factura: </strong>
                                <span>{props.name}</span>
                            </p>

                            <p>
                                <strong>Ubicación del Vehiculo: </strong>
                                <span>{props.lastName}</span>
                            </p>

                            <p>
                                <strong>Teléfono: </strong>
                                <span>{props.phone}</span>
                            </p>

                            <p>
                                <strong>Email: </strong>
                                <span>{props.email}</span>
                            </p>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    </>;
}

export default Contact;