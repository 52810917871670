// components
import {
    Box,
    Grid,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'

// images
import kia from '../../../assets/images/brands/home-marcas_kia_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import nissan from '../../../assets/images/brands/home-marcas_nissan_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import vw from '../../../assets/images/brands/home-marcas_vw_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import toyota from '../../../assets/images/brands/home-marcas_toyota_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import honda from '../../../assets/images/brands/home-marcas_honda_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import mercedes from '../../../assets/images/brands/home-marcas_mercedes_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import audi from '../../../assets/images/brands/home-marcas_audi_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import ford from '../../../assets/images/brands/home-marcas_ford_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import gmc from '../../../assets/images/brands/home-marcas_gmc_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import cupra from '../../../assets/images/brands/home-marcas_cupra_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import changan from '../../../assets/images/brands/home-marcas_changan_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import suzuki from '../../../assets/images/brands/home-marcas_suzuki_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import { Image } from '../../shared/Image/Image'
import { PreloadImages } from '../../shared/PreloadImages/PreloadImages'

// private vars
const brands = [
    {
        image: kia,
        alt: 'home-marcas_kia_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Kia',
    },
    {
        image: nissan,
        alt: 'home-marcas_nissan_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Nissan',
    },
    {
        image: vw,
        alt: 'home-marcas_vw_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Volkswagen',
    },
    {
        image: toyota,
        alt: 'home-marcas_toyota_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Toyota',
    },
    {
        image: honda,
        alt: 'home-marcas_honda_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Honda',
    },
    {
        image: mercedes,
        alt: 'home-marcas_mercedes_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Mercedes Benz',
    },
    {
        image: audi,
        alt: 'home-marcas_audi_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Audi',
    },
    {
        image: ford,
        alt: 'home-marcas_ford_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Ford',
    },
    {
        image: gmc,
        alt: 'home-marcas_gmc_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'GMC',
    },
    {
        image: cupra,
        alt: 'home-marcas_cupra_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Cupra',
    },
    {
        image: changan,
        alt: 'home-marcas_changan_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Changan',
    },
    {
        image: suzuki,
        alt: 'home-marcas_suzuki_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        label: 'Suzuki',
    },
]

// component
function HomeBrands() {
    const renderBrands = (quantity = 3) => {
        const componenets = []
        const containers = []
        let items = []

        for (const brand of brands) {
            items.push(brand)

            if (items.length === quantity) {
                containers.push(items)
                items = []
            }
        }

        if (items.length > 0 && items.length < quantity) {
            containers.push(items)
        }

        for (const containerIndex in containers) {
            const container = containers[containerIndex]
            const gridItems = []

            for (const itemIndex in container) {
                const brand = container[itemIndex]
                const gridItem = <Grid item
                    key={`brand_${containerIndex}_${itemIndex}`}
                    xs={12 / quantity}
                    className='flex justify-center'
                >
                    <Box className='grid place-items-center w-[80px] h-[80px] my-[20px] border rounded-lg'>
                        <Image image={brand.image} alt={brand.alt} style={{width: '90px', height: '80px'}}/>
                    </Box>
                </Grid>
                gridItems.push(gridItem)
            }

            componenets.push(<Grid container
                key={`brand_container_${containerIndex}`}
                spacing={2}
                alignItems='center'
                justifyContent='center'
            >
                {gridItems}
            </Grid>)
        }

        return componenets
    }

    return <Box className="container-fluid"
        sx={{
            backgroundColor: '#fff',
        }}
    >
        <PreloadImages images={brands} />
        <Box className="container p-[20px]">
            <Grid container
                spacing={2}
                alignItems='center'
                justifyContent='center'
                className='p-[20px]'
            >
                <h4 className='font-title text-4xl text-center py-[20px]'>
                    Encuentra las marcas de autos preferidos en México
                </h4>
            </Grid>

            <Grid container
                spacing={2}
                alignItems='center'
                justifyContent='center'
                display={{
                    xs: 'none',
                    sm: 'flex',
                }}
            >
                {brands.map((brand, index) => <Grid item key={`brand_${index}`}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    xs={3}
                    md={2}
                >
                    <RouterLink
                        className='grid place-items-center w-[100px] h-[100px] my-[20px] border rounded-lg'
                        to={`/compra-tu-auto?marca=${brand.label}`}
                    >
                        <Image image={brand.image} alt={brand.alt} style={{width: '90px', height: '80px'}}/>
                    </RouterLink>
                </Grid>)}
            </Grid>

            <Box sx={{
                display: {
                    xs: 'block',
                    sm: 'none',
                }
            }}>
                <Carousel
                    navButtonsAlwaysVisible
                    animation='slide'
                >
                    {renderBrands()}
                </Carousel>
            </Box>
        </Box>
    </Box>
}

// export
export default HomeBrands
