// import
import {
    Box,
    Button,
    Grid,
} from '@mui/material'

// icons
import {
    RateReview,
    Handshake,
    TimeToLeave,
    Paid,
} from '@mui/icons-material'
import ReactPixel from 'react-facebook-pixel';
// images
import headerImg from '../../../assets/images/sellcar/vende-tu-auto_check_888x480-webp_autos-seminuevos_venta-de-autos_auto_seminuevo.webp'
import sellButton from '../../../assets/images/sellcar/vende-tu-auto_cta_190x40-svg_autos-seminuevos_venta-de-autos_auto_seminuevo.svg'

// private const
const styleIcon = {
    fontSize: 50,
    color: '#00ce7c',
}

const images = {
    headerImg: {
        image: headerImg,
        alt: 'vende-tu-auto_check_888x480-webp_autos-seminuevos_venta-de-autos_auto_seminuevo',
    },
    sellButton: {
        image: sellButton,
        alt: 'vende-tu-auto_cta_190x40-svg_autos-seminuevos_venta-de-autos_auto_seminuevo',
    },
    icons: [
        {
            icon: <RateReview sx={styleIcon} />,
            title: 'Cotiza',
            text: 'Ingresa toda la información solicitada',
        },
        {
            icon: <Handshake sx={styleIcon} />,
            title: 'Recibe',
            text: 'Instantáneamente recibes oferta Nowy',
        },
        {
            icon: <TimeToLeave sx={styleIcon} />,
            title: 'Agenda',
            text: 'Programas tu cita de atención',
        },
        {
            icon: <Paid sx={styleIcon} />,
            title: 'Obtén',
            text: 'En menos de 24 hrs. recibes el pago',
        },
    ],
}

// component
function SellCarHeader({ setShowForm }) {



    const onShowForm = () => {
        ReactPixel.trackCustom('Iniciar proceso Vende');
        setShowForm(true);
    }
    // render component
    return <>
        <Box className="container mb-[20px]">
            <Box sx={{
                marginY: {
                    md: '20px',
                    lg: '20px',
                    xl: '20px',
                },

            }}>
                <Grid container
                    spacing={2}
                    alignItems="center"
                >
                    <Grid item
                        xs={12}
                        md={6}
                    >
                        <Box className='max-w-[400px] p-[20px] '>
                            <h2 className='font-title text-4xl py-[10px]'>
                                Cotiza y vende en pocos clicks
                            </h2>

                            <p className='font-text text-lg'>
                                En segundos obtienes la cotización de tu vehículo
                            </p>



                            <Button
                                variant="contained"
                                className="w-48 rounded-full"
                                onClick={ onShowForm }
                                sx={{
                                    "border-radius": '20px',
                                    backgroundColor: "#63C088",
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: '#000',
                                    },
                                }}
                            >
                                Vender tu Auto
                            </Button>



                        </Box>
                    </Grid>

                    <Grid item
                        xs={12}
                        md={6}
                    >
                        <Box className='full-w h-[300px]'
                            sx={{
                                backgroundColor: '#000',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundImage: `url(${images.headerImg.image})`,
                                display: {
                                    xs: 'none',
                                    md: 'block',
                                },
                            }}
                        />

                        <Box className='full-w px-[20px]' sx={{
                            display: {
                                xs: 'block',
                                md: 'none',
                            },
                        }}>
                            <img
                                src={images.headerImg.image}
                                alt={images.headerImg.alt}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box className='mt-10 mb-8bg-gray-100'>
                <Grid container

                    alignItems="center"

                    minHeight={300}
                >
                    {images.icons.map((icon, index) => (
                        <Grid key={`sellcar_header_icon_${index}`}
                            item
                            xs
                            sm={6}
                            md={3}
                            className='grid place-items-center'
                        >
                            <Box className='w-[200px] h-[200px]'>
                                <Box className='grid place-items-center pb-[10px]'>
                                    <Box className='w-[50px] h-[50px]'>
                                        {icon.icon}
                                    </Box>
                                </Box>

                                <h4 className='font-title text-2xl py-[4px] text-center px-[20px]'>
                                    {icon.title}
                                </h4>

                                <p className='font-text text-lg py-[4px] text-center px-[20px]'>
                                    {icon.text}
                                </p>
                            </Box>
                        </Grid>
                    ))}
                </Grid>



                <Box className='flex justify-center mb-8 pb-5'>

                    <Button
                        variant="contained"
                        className="w-48 rounded-full mt-[20px]"
                        onClick={() => {
                            setShowForm(true)
                        }}
                        sx={{
                            "border-radius": '20px',
                            backgroundColor: "#63C088",
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: '#000',
                            },
                        }}
                    >
                        Cotizar Vehiculo
                    </Button>


                </Box>
            </Box>
        </Box>
    </>
}

export default SellCarHeader
