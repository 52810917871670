import axios from 'axios'

const get = (catalog, params = null) => {
    return new Promise((resolve) => {
        axios({
            method: 'get',
            url: `/api/valua/${catalog}`,
            params,
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => { })
    });
}

const years = () => {
    return get('list-years')
}

const brands = (year) => {
    return get('list-brands', {
        year,
    })
}

const models = (year, brandId) => {
    return get(`list-models`, {
        year,
        brandId,
    })
}

const version = (year, brandId, modelId) => {
    return get(`list-versions`, {
        year,
        brandId,
        modelId
    })
}

const color = () => {
    return new Promise((resolve) => {
        resolve([
            {
                color: 'rgb(255, 251, 102)',
                name: 'Amarillo',
            },
            {
                color: 'rgb(24, 61, 106)',
                name: 'Azul',
            },
            {
                color: 'rgb(239, 206, 154)',
                name: 'Beige',
            },
            {
                color: 'rgb(255, 255, 255)',
                name: 'Blanco',
            },
            {
                color: 'rgb(121, 92, 50)',
                name: 'Café',
            },
            {
                color: 'rgb(239, 200, 112)',
                name: 'Dorado',
            },
            {
                color: 'rgb(128, 128, 128)',
                name: 'Gris',
            },
            {
                color: 'rgb(176, 95, 178)',
                name: 'Morado',
            },
            {
                color: 'rgb(234, 108, 32)',
                name: 'Naranja',
            },
            {
                color: 'rgb(0, 0, 0)',
                name: 'Negro',
            },
            {
                color: 'rgb(244, 7, 6)',
                name: 'Rojo',
            },
            {
                color: 'rgb(183, 188, 96)',
                name: 'Verde',
            },
        ])
    })
}

const states = () => {
    return get('list-states')
}

const cities = (stateId) => {
    return get(`list-cities/${stateId}`)
}

const price = (year, brandId, modelId, versionClave, km, color) => {
    return get(`list-car-prices`, {
        year,
        brandId,
        modelId,
        versionClave,
        km,
        color,
    })
}

const ValuaService = {
    get,
    years,
    brands,
    models,
    version,
    states,
    cities,
    price,
    color,
}

export default ValuaService
