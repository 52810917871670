// components
import {
    Box,
    Grid,
} from '@mui/material'

// slider images
import garantia from '../../../assets/images/home/services/home-encuentra-garantia_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import demand from '../../../assets/images/home/services/home-encuentra-autos-on-demand_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import credito from '../../../assets/images/home/services/home-encuentra-credito_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import asesoria from '../../../assets/images/home/services/home-encuentra-asesoria_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
import { Image } from '../../shared/Image/Image'

// private vars
const services = [
    {
        image: garantia,
        alt: 'home-encuentra-garantia_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        title: 'GARANTÍA',
        desc: 'Seminuevos con reembolso garantizado de 7 días y con 3 meses de protección, condiciones de agencia. Mejor servicio imposible.',
    },
    {
        image: demand,
        alt: 'home-encuentra-autos-on-demand_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        title: 'AUTOS ON DEMAND',
        desc: 'Si no hallas tu automóvil en nuestro inventario tenemos convenios con más de 8000 vehículos. Lo negociamos y entregamos para ti.',
    },
    {
        image: credito,
        alt: 'home-encuentra-credito_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        title: 'CRÉDITO A TU MEDIDA',
        desc: 'Financiamiento personalizado, tú eliges y nosotros nos encargamos de poner el auto de tus sueños en tus manos.',
    },
    {
        image: asesoria,
        alt: 'home-encuentra-asesoria_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
        title: 'ASESORÍA PERSONALIZADA',
        desc: 'Estamos a tu lado en todo momento, lo digital es la nueva forma de comprar un auto. Confidencialidad en tú información.',
    },
]

// component
function HomeServices() {
    return <Box className="container-fluid"
        sx={{
            backgroundColor: '#fff',
        }}
    >
        <Box className="container"
            sx={{
                paddingY: '20px',
            }}
        >
            <Grid container
                spacing={2}
                alignItems='center'
                justifyContent='center'
                sx={{
                    padding: '20px',
                }}
            >
                <h4 className='max-w-[800px] font-title text-4xl text-center mb-[20px] px-[20px]'>
                    Encuentra el auto de tu sueños de la manera más rápida y fácil
                </h4>
            </Grid>

            <Grid container
                spacing={2}
                alignItems='center'
                justifyContent='center'
                sx={{
                    padding: '20px',
                }}
            >
                {services.map((service, index) => <Grid item
                    key={`service_${index}`}
                    xs={12}
                    md={6}
                    lg={3}
                >
                    <center>
                        <Box sx={{
                            padding: '20px',
                            maxWidth: '300px',
                        }}>

                            <Image image={service.image} alt={service.alt} />
                            <br />

                            <p className='font-subtitle text-center text-brandPrimary'>
                                {service.title}
                            </p>
                            <br />

                            <p className='font-regular text-center'>
                                {service.desc}
                            </p>
                        </Box>
                    </center>
                </Grid>)}
            </Grid>
        </Box>
    </Box>
}

// export
export default HomeServices
