import { Navigate, Route, Routes } from 'react-router-dom'
import SellCar from '../SellCar'

export const SellCarRoutes = () => {
  return (
    <Routes>
    <Route path="/*" element={<Navigate to='/'/>}/>
    <Route path="/" element={<SellCar/>}/>
    <Route path="/anio" element={<SellCar/>} />
    <Route path="/anio/marca" element={<SellCar/>} />
    <Route path="/anio/marca/modelo" element={<SellCar/>} />
    <Route path="/anio/marca/modelo/version" element={<SellCar/>} />
    <Route path="/anio/marca/modelo/version/color" element={<SellCar/>} />
    <Route path="/anio/marca/modelo/version/color/kms" element={<SellCar/>} />
    <Route path="/anio/marca/modelo/version/color/kms/contacto" element={<SellCar/>} />
    <Route path="/anio/marca/modelo/version/color/kms/contacto/valuacion" element={<SellCar/>} />
    <Route path="/anio/marca/modelo/version/color/kms/contacto/valuacion/agendar" element={<SellCar/>} />
    <Route path="/anio/marca/modelo/version/color/kms/contacto/valuacion/agendar/detalles" element={<SellCar/>} />

</Routes>
  )
}
