// component
function Certificate(props) {
    // render component
    return <>
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 24} height={props.height || 34} viewBox="0 0 24 34">
            <g id="medal" transform="translate(-71.962)">
                <path id="Path_20" data-name="Path 20" d="M89.3,23.828l1.527-2.523,2.755-.957.305-2.947,2.073-2.078-.985-2.789.985-2.79L93.889,7.666l-.305-2.947-2.755-.957L89.3,1.239l-2.888.355L83.962,0,81.509,1.594l-2.887-.355L77.1,3.762l-2.755.957-.305,2.947L71.962,9.744l.985,2.79-.985,2.79L74.035,17.4l.305,2.947,2.755.957,1.527,2.523,2.888-.355,2.453,1.594,2.453-1.594ZM75.523,12.534a8.441,8.441,0,1,1,16.879,0,8.441,8.441,0,1,1-16.879,0Z" transform="translate(0 0)" fill="#94B77D"></path>
                <path id="Path_21" data-name="Path 21" d="M163.072,89.333a6.6,6.6,0,1,0,6.482,6.6A6.55,6.55,0,0,0,163.072,89.333Z" transform="translate(-79.11 -83.401)" fill="#94B77D"></path>
                <path id="Path_22" data-name="Path 22" d="M89.039,345.326l-3.435.422-1.815-3-.617-.214-2.661,8.471,4.791-.268,3.748,3.051,2.206-7.021Z" transform="translate(-7.992 -319.789)" fill="#94B77D"></path>
                <path id="Path_23" data-name="Path 23" d="M274.168,342.749l-1.815,3-3.435-.422-2.217,1.441,2.206,7.021,3.748-3.051,4.791.268-2.661-8.471Z" transform="translate(-182.041 -319.789)" fill="#94B77D"></path>
            </g>
        </svg>
    </>
}

export default Certificate

