// import
import {
    Card,
    CardContent,
    FormHelperText,
    Input,
    Select,
    MenuItem,
    Button,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import SyncIcon from '@mui/icons-material/Sync'

// styles
import '../ConsignCar.scss'

// private props
const activities = [
    'Empleado',
    'Freelancer',
    'Consultor Independiente',
    'Negocio Propio',
    'Ventas/Comerciante',
    'Jubilado',
    'Empleado Doméstico',
    'Otro',
]

// component
function Form(props) {

    // render component
    return <>
        <div className="sell-car-content">
            <div className="flex justify-center mt-6 mb-4">
                <Card className="sell-car-list">
                    <CardContent>
                        <p className="font-bold text-lg text-center">
                            Consigna tu auto
                        </p>

                        <p className="text-md text-center pb-4">
                            Todos sus datos serán tratados en forma confidencial.
                        </p>

                        <div className="grid sm:grid-cols-1 md:grid-cols-2">
                            <div className="p-2">
                                <Select
                                    fullWidth
                                    className="px-2 py-0 bg-slate-200 rounded-md"
                                    value={props.brand.id}
                                    onChange={(event) => {
                                        let brand = null
                                        for (let item of props.brands) {
                                            if (item.id === event.target.value) {
                                                brand = item
                                                break
                                            }
                                        }

                                        props.onChange('brand', brand)
                                    }}
                                >
                                    <MenuItem value={0}>
                                        <span className="text-slate-400">
                                            Marca
                                        </span>
                                    </MenuItem>

                                    {props.brands.length > 0 ?
                                        props.brands.map((item, index) => (
                                            <MenuItem
                                                key={`brand_${index}`}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))
                                        : null}
                                </Select>
                                <FormHelperText className="error-label">{props.brandError}</FormHelperText>
                            </div>

                            <div className="p-2">
                                <Select
                                    fullWidth
                                    className="px-2 py-0 bg-slate-200 rounded-md"
                                    value={props.model.id}
                                    onChange={(event) => {
                                        let model = null
                                        for (let item of props.models) {
                                            if (item.id === event.target.value) {
                                                model = item
                                                break
                                            }
                                        }

                                        props.onChange('model', model)
                                    }}
                                >
                                    <MenuItem value={0}>
                                        <span className="text-slate-400">
                                            Modelo
                                        </span>
                                    </MenuItem>

                                    {props.models.length > 0 ?
                                        props.models.map((item, index) => (
                                            <MenuItem
                                                key={`model_${index}`}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))
                                        : null}
                                </Select>
                                <FormHelperText className="error-label">{props.modelError}</FormHelperText>
                            </div>

                            <div className="p-2">
                                <Input
                                    fullWidth
                                    disableUnderline
                                    className="px-2 py-1 bg-slate-200 rounded-md"
                                    value={props.budget}
                                    placeholder="Presupuesto"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                    }}
                                    onChange={(event) => {
                                        props.onChange('budget', event.target.value)
                                    }}
                                />
                                <FormHelperText className="error-label">{props.budgetError}</FormHelperText>
                            </div>

                            <div className="p-2">
                                <Select
                                    fullWidth
                                    className="px-2 py-0 bg-slate-200 rounded-md"
                                    value={props.activity}
                                    onChange={(event) => {
                                        props.onChange('activity', event.target.value)
                                    }}
                                >
                                    <MenuItem value={'empty'}>
                                        <span className="text-slate-400">
                                            Tipo de Actividad
                                        </span>
                                    </MenuItem>

                                    {activities.length > 0 ?
                                        activities.map((activity, index) => (
                                            <MenuItem
                                                key={`brand_${index}`}
                                                value={activity}
                                            >
                                                {activity}
                                            </MenuItem>
                                        ))
                                        : null}
                                </Select>
                                <FormHelperText className="error-label">{props.activityError}</FormHelperText>
                            </div>

                            <div className="p-2">
                                <Input
                                    fullWidth
                                    disableUnderline
                                    className="px-2 py-1 bg-slate-200 rounded-md"
                                    value={props.credit}
                                    placeholder="Capture el monto deseado por su automovil"
                                    onChange={(event) => {
                                        props.onChange('credit', event.target.value)
                                    }}
                                />
                                <FormHelperText className="error-label">{props.creditError}</FormHelperText>
                            </div>

                            <div />

                            <div className="p-2">
                                <Input
                                    fullWidth
                                    disableUnderline
                                    className="px-2 py-1 bg-slate-200 rounded-md"
                                    value={props.name}
                                    placeholder="Nombre del propietario en la Factura"
                                    onChange={(event) => {
                                        props.onChange('name', event.target.value)
                                    }}
                                />
                                <FormHelperText className="error-label">{props.nameError}</FormHelperText>
                            </div>

                            <div className="p-2">
                                <Input
                                    fullWidth
                                    disableUnderline
                                    className="px-2 py-1 bg-slate-200 rounded-md"
                                    value={props.lastName}
                                    placeholder="Ubicación del Vehiculo"
                                    onChange={(event) => {
                                        props.onChange('lastName', event.target.value)
                                    }}
                                />
                                <FormHelperText className="error-label">{props.lastNameError}</FormHelperText>
                            </div>

                            <div className="p-2">
                                <Input
                                    fullWidth
                                    disableUnderline
                                    className="px-2 py-1 bg-slate-200 rounded-md"
                                    value={props.phone}
                                    placeholder="Teléfono"
                                    type="tel"
                                    onChange={(event) => {
                                        props.onChange('phone', event.target.value)
                                    }}
                                />
                                <FormHelperText className="error-label">{props.phoneError}</FormHelperText>
                            </div>

                            <div className="p-2">
                                <Input
                                    fullWidth
                                    disableUnderline
                                    className="px-2 py-1 bg-slate-200 rounded-md"
                                    value={props.email}
                                    placeholder="Email"
                                    type="email"
                                    onChange={(event) => {
                                        props.onChange('email', event.target.value)
                                    }}
                                />
                                <FormHelperText className="error-label">{props.emailError}</FormHelperText>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>

            <div className="flex justify-center mb-4">
                <Button
                    disabled={props.loading}
                    variant="contained"
                    className="sell-car-button"
                    endIcon={props.loading ? <SyncIcon /> : <SendIcon />}
                    onClick={props.continueAction}
                >
                    {props.loading ? 'ENVIANDO' : 'CONTINUAR'}
                </Button>
            </div>
        </div>
    </>
}

export default Form;