import axios from "axios";

export const handleProduct = async (products) => {
  try {
    const response = await axios.post("/checkout", products);

    const session = response.data;

    // Crear un producto y redirigir al formulario de pago de Stripe
    return session.id;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-lanzar el error para manejarlo en el archivo principal si es necesario
  }
};
