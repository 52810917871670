import MenuIcon from '@mui/icons-material/Menu'
import JoyLink from '@mui/joy/Link'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'

// import logo_white from '../../../assets/images/logos/logo-white.png'

import logo_white from '../../../assets/images/logos/logo-white.webp'
import './Header.scss'

const drawerWidth = 240;
const navItems = [{
    label: 'Compra tu Auto',
    path: '/compra-tu-auto',
}, {
    label: 'Vende tu Auto',
    path: '/vende-tu-auto',
}, {
    label: 'Consigna tu auto',
    path: '/consigna-tu-auto',
}, {
    label: 'Servicios Automotrices',
    path: '/servicios-automotrices',
}, {
    label: 'Contacto',
    path: '/',
    color: '#000',
    bgColor: '#00CE7C',
}]

function Header() {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const renderMenuItems = () => {
        const items = []

        for (const index in navItems) {
            const item = navItems[index]

            const buttonStyle = {
                color: item.color || '#FFF',
                backgroundColor: item.bgColor || 'transparent',
                borderRadius: '16px',
                textTransform: 'none',
                paddingX: '12px',
                '&:hover': {
                    color: item.color || '#FFF',
                    backgroundColor: item.bgColor || 'transparent',
                    opacity: 0.65,
                },
            }

            if (item.href) {
                items.push(
                    <Button
                        key={`menu_item_${index}`}
                        onClick={() => {
                            window.open(item.href)
                        }}
                        sx={buttonStyle}
                    >
                        {item.label}
                    </Button>
                )
            }
            else if (item.path) {
                items.push(
                    <RouterLink
                        key={`menu_item_${index}`}
                        to={item.path}
                    >
                        <Button
                            sx={buttonStyle}
                        >
                            {item.label}
                        </Button>
                    </RouterLink>
                )
            }
        }

        return items
    }

    const renderDrawerItems = () => {
        const items = [
            <RouterLink
                key={`drawer_item_home`}
                to="/"
            >
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemText
                            className="text-white"
                            primary="Inicio"
                        />
                    </ListItemButton>
                </ListItem>
            </RouterLink>
        ]

        for (let index in navItems) {
            let item = navItems[index]

            if (item.href) {
                items.push(
                    <ListItem
                        key={`drawer_item_${index}`}
                        disablePadding
                    >
                        <ListItemButton
                            onClick={() => {
                                window.open(item.href)
                            }}
                        >
                            <ListItemText
                                className="text-white"
                                primary={item.label}
                            />
                        </ListItemButton>
                    </ListItem>
                )
            }
            else if (item.path) {
                items.push(
                    <RouterLink
                        key={`drawer_item_${index}`}
                        to={item.path}
                    >
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText
                                    className="text-white"
                                    primary={item.label}
                                />
                            </ListItemButton>
                        </ListItem>
                    </RouterLink>
                )
            }
        }

        return items
    }

    const drawer = (
        <Box
            onClick={handleDrawerToggle}
        >
            <div className="flex justify-center pt-6 pb-4">
                <img src={logo_white} className="logo" alt="Logo Nowy" style={{width: '100px', height: '32px'}}/>
            </div>

            <List>
                {renderDrawerItems()}
            </List>
        </Box>
    );

    return (
        <div id="main-header" className="container-fluid mx-auto">
            <center>
                <AppBar
                    component="nav"
                    color="transparent"
                    position="relative"
                    sx={{
                        boxShadow: 'none',
                        maxWidth: '1200px',
                    }}
                >
                    <Toolbar>
                        <Box
                            variant="h6"
                            component="div"
                            className="py-4 px-8"
                            sx={{
                                display: {
                                    sm: 'block'
                                },
                                textAlign: 'left',
                            }}
                        >
                            <JoyLink
                                component={RouterLink}
                                to="/"
                                sx={{
                                    '&:hover': {
                                        opacity: 0.65,
                                    },
                                }}
                            >
                                <img
                                    src={logo_white}
                                    alt="Logo Nowy"
                                    width="150px"
                                    height="48px"

                                />
                            </JoyLink>
                        </Box>

                        <Box sx={{
                            flexGrow: 1,
                            display: {
                                sm: 'block',
                            },
                        }} />

                        <Box sx={{
                            display: {
                                xs: 'none',
                                lg: 'block',
                            },
                        }}>
                            {renderMenuItems()}
                        </Box>

                        <IconButton
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerToggle}
                            sx={{
                                mr: 2,
                                display: {
                                    lg: 'none',
                                },
                                color: '#fff',
                                '&:hover': {
                                    opacity: 0.65,
                                },
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </center>

            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true
                    }}
                    sx={{
                        display: {
                            xs: 'block',
                            lg: 'none',
                        },
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            backgroundColor: 'rgb(30,41,59)',
                        },
                        '& .MuiListItemText-primary': {
                            fontSize: 14,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </div>
    );
}

export default Header;