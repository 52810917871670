import axios from 'axios';

const search = (params) => {
    return new Promise((resolve) => {
        axios.get(`/api/list-cars?${params}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => { })
    });
}

const getCar = (id) => {
    return new Promise((resolve) => {
        axios.get(`/api/list-one/${id}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => { })
    });
}

const SearchService = {
    search,
    getCar,
}

export default SearchService
