// react
import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Box,
    Grid,
    TextField,
    Autocomplete,
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { useInView } from 'react-intersection-observer';

// helpers
import catalogsHelper from '../../../helpers/catalogs';

// services
import catalogsService from '../../../services/catalogsService';

// slider images
// import sliderImage1 from '../../../assets/images/home/main-slider/home-portada1_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.webp'
// import sliderImage2 from '../../../assets/images/home/main-slider/home-portada_vw2_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.png'
// import sliderImage3 from '../../../assets/images/home/main-slider/home-portada_vw-teramont_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.png'
// import sliderImage4 from '../../../assets/images/home/main-slider/home-portada_mini-cooper_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.png'
// import sliderImage5 from '../../../assets/images/home/main-slider/home-portada_ford_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.png'

import sliderImage1 from '../../../assets/images/home/main-slider/home-portada1_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.avif'
import sliderImage2 from '../../../assets/images/home/main-slider/home-portada_vw2_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.avif'
import sliderImage3 from '../../../assets/images/home/main-slider/home-portada_vw-teramont_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.avif'
import sliderImage4 from '../../../assets/images/home/main-slider/home-portada_mini-cooper_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.avif'
import sliderImage5 from '../../../assets/images/home/main-slider/home-portada_ford_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy.avif'
import { PreloadImages } from '../../shared/PreloadImages/PreloadImages'
// import { Image } from '../../shared/Image/Image';

// private vars
const { sortCatalog } = catalogsHelper;
const sliderImages = [
    {
        image: sliderImage1,
        alt: 'home-portada1_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
    },
    {
        image: sliderImage2,
        alt: 'home-portada_vw2_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
    },
    {
        image: sliderImage3,
        alt: 'home-portada_vw-teramont_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
    },
    {
        image: sliderImage4,
        alt: 'home-portada_mini-cooper_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
    },
    {
        image: sliderImage5,
        alt: 'home-portada_ford_autos-seminuevos_venta-de-autos_auto_seminuevos_nowy',
    },
];

// component
function HomeSlider() {
    // hooks
    const navigate = useNavigate();
    const { ref, inView } = useInView({
        triggerOnce: true, // Ensure the animation only happens once
        threshold: 0.1, // Adjust based on how much of the component should be in view to trigger the load
    });

    // state
    const [brandsOptions, setBrandsOptions] = useState([]);

    // init effect
    const initComponent = useCallback(() => {
        catalogsService.brands().then(brands => {
            const sortedBrands = sortCatalog(brands);
            const brandLabels = [];
            sortedBrands.forEach(brand => {
                brandLabels.push({
                    label: brand.name,
                });
            });
            setBrandsOptions(brandLabels);
        });
    }, []);

    // effects
    useEffect(() => {
        if (inView) {
            initComponent();
        }
    }, [initComponent, inView]);

    // render component
    if (!inView) {
        return <div ref={ref} style={{ height: '500px' }}></div>;
    }

    return (
        <>
            <PreloadImages images={sliderImages} />
            <Box className="container-fluid"
                sx={{
                    paddingY: '20px',
                    backgroundColor: '#fff',
                }}
            >
                <Grid container
                    spacing={2}
                    alignItems="center"
                    sx={{
                        height: {
                            lg: '500px',
                            xs: 'auto',
                        },
                        width: {
                            lg: 'calc(100% + 16px)',
                            xs: '100%'
                        },
                        marginLeft: {
                            lg: '16px',
                            xs: '0px'
                        }
                    }}
                >
                    <Grid item
                        xs={12}
                        lg={6}
                        sx={{
                            display: 'flex',
                            justifyContent: {
                                xs: 'center',
                                lg: 'flex-end',
                            },
                            paddingLeft: {
                                xs: '0px',
                                lg: '16px'
                            }
                        }}
                    >
                        <Box sx={{
                            width: {
                                xs: '100%',
                                sm: '85%',
                                md: '85%',
                                lg: '400px',
                                xl: '500px',
                            },
                            marginRight: {
                                lg: '120px',
                            },
                            padding: '20px',
                        }}>
                            <h6 className='font-subtitle text-xl'>
                                EL AUTO DE TUS SUEÑOS
                            </h6>
                            <h4 className='font-title text-4xl'>
                                Vive una experiencia
                            </h4>
                            <h4 className='font-title text-4xl'>
                                increíble con Nowy
                            </h4>
                            <p className='font-text text-base mt-[20px]'>
                                Ofrecemos soluciones a tu movilidad en un click. Somos una agencia especialista con 48 años de experiencia.
                            </p>
                            <p className='font-text text-base'>
                                Orgullosamente somos parte de <a className='text-brandPrimary' href='https://www.alerta.com.mx/'>Grupo Alerta</a>; queremos ayudarte a obtener los mejores seminuevos y que vivas.
                            </p>
                            <p className='font-text text-base'>
                                la mejor experiencia de comprar, vender y consignar tu vehículo.
                            </p>
                            <Box id='brand-selector' className='mt-[20px]'>
                                <Autocomplete
                                    disablePortal
                                    options={brandsOptions}
                                    onChange={(event, values) => {
                                        if (values) {
                                            navigate(`/compra-tu-auto?marca=${values.label}`)
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Buscar por marca'
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item
                        xs={12}
                        lg={6}
                        sx={{
                            display: 'flex',
                            justifyContent: {
                                xs: 'center',
                                lg: 'flex-end',
                            },
                            overflow: 'hidden',
                            paddingLeft: {
                                xs: '0px',
                                lg: '16px'
                            }
                        }}
                    >
                        <Carousel
                            className='w-full'
                            navButtonsAlwaysInvisible
                            animation='slide'
                        >
                            {sliderImages.map((sliderImage, index) => <Box key={`slider-${index}`}
                                className='flex justify-end'
                            >
                                <img
                                    className="max-h-[350px] w-auto xl:max-h-[450px] xl:w-[800px]"
                                    src={sliderImage.image}
                                    alt={sliderImage.alt}
                                />
                            </Box>)}
                        </Carousel>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

// export
export default HomeSlider;