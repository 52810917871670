// images
import logo from '../../assets/images/logos/logo.png'
import {
    Card,
    CardContent,
    Typography,
    Button
} from '@mui/material'

// styles
import '../Services/Services.scss'

// component
function PagoDigital() {
    // render component
    return <>
        <section id="services">

        
            <div id="services-header">
                <div className="mt-12 mb-12 flex justify-center">
                    <img
                        src={logo}
                        alt="Logo Nowy"
                        width="200px"
                    />
                </div>
            </div>


            <div className="services-content">
                <div className="flex justify-center">
                    <Card className="services-card py-4">
                        <CardContent>
                            <Typography className="card-title text-center">
                                Confianza y Seguridad en su Pago Digital
                            </Typography><br/>

                            <Typography className="card-subtitle text-justify">
                                <span className='font-bold'>Antes de pagar con su tarjeta de débito o crédito:</span> Al realizar su pago con seguridad digital, realiza
                                el apartado y reserva de un automóvil hasta por 7 días para un seminuevo de su preferencia. Deberá copiar el QR# de 
                                nuestro inventario fotográfico(Compra Tu Auto) que le será requerido. <br /><span className='font-bold'>Después del QR#:</span> recibiremos su pago de $ 5,000/ $ 10,000 pesos REEMBOLSABLE, 
                                en caso de que no lleve a final de término la compra de su vehículo Nowy. Es importante ingresar todos los datos que le son requeridos en nuestra 
                                solicitud de apartado digital a continuación.
                            </Typography>

                            <p className='mt-4'>
                                <div className="flex justify-center">
                                        <Button
                                            variant="contained"
                                            className="service-button"
                                            onClick={event =>  window.location.href='https://buy.stripe.com/dR628KcU27868De3cc'}
                                        >
                                            Su Pago Digital &lt; Aqui &gt;
                                        </Button>
                                </div>
                            </p>

                            <Typography className="card-subtitle text-justify mt-4 pt-4">
                                <span className='font-bold'>+12</span> Realizaron un apartado por su auto con nuestra herramienta en los últimos días
                            </Typography>

                            <Typography className="card-subtitle text-justify mt-4 pt-4">
                                 Información Whatsapp +52 6693801862 Email <a href='mailto:atencion@nowy.mx' className="text-blue-600">atencion@nowy.mx</a>
                            </Typography>

                            <Typography className="card-title text-center mt-4 pt-4">
                                Te Movemos en un &lt; Click &gt;
                            </Typography><br/>

                        </CardContent>
                    </Card>
                </div>
            </div>



            

        </section>
        </>
}

export default PagoDigital;