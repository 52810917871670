// import
import {
    Card,
    CardContent,
    Box,
    Typography,
    Button,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import SyncIcon from '@mui/icons-material/Sync'

// styles
import '../Services.scss'

// component
function Appointment(props) {

    // render component
    return <>
        <div className="services-content">
            <div className="flex justify-center">
                <Card className="services-card py-4">
                    <CardContent>
                        <Typography className="card-title text-center">
                            Agendar una Cita con los Expertos
                        </Typography>

                        <Typography className="card-subtitle text-center">
                            Finalmente, elige la fecha que más se adapte a tus necesidades
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            <div className="flex justify-center mt-6 mb-4">
                <Card className="services-list">
                    <CardContent>
                        <p className="font-bold text-lg text-center pb-4">
                            Selecciona la fecha de tu cita
                        </p>

                        <div className='grid sm:grid-cols-1 md:grid-cols-3 pb-4'>
                            {props.dates.map((date, index) => (
                                <div
                                    key={`date_${index}`}
                                    className='p-4 flex justify-center'
                                >
                                    <Box
                                        className={`p-1 ${props.appointment?.date === date.value?.date ? 'date-active' : ''}`}
                                        sx={{
                                            width: '100%',
                                            maxWidth: '150px',
                                            boxShadow: '0px 0px 12px 0px rgba(23,23,23,0.2)',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            props.onSelectDate(date.value)
                                        }}
                                    >
                                        <Box
                                            className='py-4 border-2 border-black-50'
                                            sx={{
                                                backgroundColor: 'white',
                                            }}
                                        >
                                            <Typography
                                                className={`text-center date-title`}
                                            >
                                                {date.day}
                                            </Typography>
                                            <Typography
                                                className='text-center date-subtitle'
                                            >
                                                {date.month}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </div>
                            ))}
                        </div>

                        <p className="error-label text-md text-center pb-4">
                            {props.appointmentError}
                        </p>
                    </CardContent>
                </Card>
            </div>

            <div className="flex justify-center">
                <Button
                    disabled={props.loading}
                    variant="contained"
                    className="service-button back-button"
                    onClick={() => {
                        if (props.backAction) {
                            props.backAction()
                        }
                    }}
                >
                    REGRESAR
                </Button>
            </div>

            <div className="flex justify-center">
                <Button
                    disabled={props.loading}
                    variant="contained"
                    className="service-button"
                    endIcon={props.loading ? <SyncIcon /> : <SendIcon />}
                    onClick={() => {
                        if (props.continueAction) {
                            props.continueAction()
                        }
                    }}
                >
                    {props.loading ? 'ENVIANDO' : 'CONTINUAR'}
                </Button>
            </div>
        </div>
    </>;
}

export default Appointment
