// import
import { 
    Button, 
    Dialog, 
    DialogContent, 
    DialogActions, 
} from '@mui/material'

// component
function Alert(props) {
    
    // render component
    return <>
        <Dialog
            open={ props.show }
            onClose={(event, reason) => {
                if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
                    return
                }

                if(props.onClose) {
                    props.onClose()
                }
            }}
        >
            <DialogContent>
                <p>
                    { props.message }
                </p>
            </DialogContent>
            
            <DialogActions>
                <Button onClick={() => {
                    if(props.onAction) {
                        props.onAction()
                    }
                }}>
                    { props.actionText }
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

export default Alert;