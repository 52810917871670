import axios from 'axios'

const send = (data) => {
    return new Promise((resolve) => {
        axios.post('api/car-services', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(err => { })
    })
}

const CarService = {
    send,
}

export default CarService
