// import
import moment from 'moment'

// test data
const testData = {
    enable: false,
    showForm: false,
    step: 'detail',
    year: 2018,
    brand: {
        "id": 12,
        "name": "Ford",
        "libroazul_clave": "270",
        "last_checked": "2023-08-27 04:27:10",
        "year": "2018"
    },
    model: {
        "id": 1494,
        "name": "Figo",
        "vehicles_catalog_brands_id": 12,
        "libroazul_clave": "900"
    },
    version: {
        "Clave": "2018270900120",
        "Nombre": "4p Impulse L4/1.5 Man A/A"
    },
    color: {
        color: 'rgb(244, 7, 6)',
        name: 'Rojo',
    },
    kms: '10000',
    cellphone: '+52 331 312 3456',
    email: 'test@test.com',
    price: '170,134.13',
    state: {
        "id": 59006,
        "state": "Jalisco"
    },
    city: {
        "id": 59006,
        "municipalite": "Guadalajara",
        "state": "Jalisco"
    },
    postalCode: '44000',
    address: 'Calle Test #123',
    date: moment(),
    hour: '11:00 a.m.',
}

// export
export default testData
