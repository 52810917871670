// import
import {
    Card,
    CardContent,
    Typography,
    Divider,
    Button,
} from '@mui/material'

// images
import credito from '../../../assets/images/services/credito.png'
import inspeccion from '../../../assets/images/services/inspeccion.png'
import revision from '../../../assets/images/services/revision.png'
import garantia from '../../../assets/images/services/garantia.png'

// styles
import '../Services.scss'

// const
const servicesList = [{
    value: 'crédito',
    title: 'Contratación Crédito Automotriz',
    subtitle: 'Tenemos los planes más atractivos',
    note: 'Disponibilidad Inmediata en Banorte, BBVA, Banregio, HSBC y Santander',
    image: credito,
}, {
    value: 'valua',
    title: 'Valua Tu Auto (Inspección Visual)',
    subtitle: 'Te hacemos la mejor oferta por tu automóvil, pagamos en 24 horas',
    note: 'Disponibilidad Inmediata',
    image: inspeccion,
}, {
    value: 'diagnostico',
    title: 'Compra Tu Servicio (Diagnostico y Revisión Mecánica)',
    subtitle: 'Nuestros Expertos te ayudarán a poner a punto tu vehículo',
    note: 'Disponibilidad Inmediata',
    image: revision,
}, {
    value: 'seguro',
    title: 'Compra Tu Seguro con hasta el 50% menos',
    subtitle: 'Nuestro plan de aseguramiento para tu vehículo es el mejor',
    note: 'Disponibilidad Inmediata',
    image: garantia,
}]

// component
function Selection(props) {

    // render component
    return <>
        <div className="services-content">
            <div className="flex justify-center">
                <Card className="services-card py-4">
                    <CardContent>
                        <Typography className="card-title text-center">
                            Agendar una Cita con los Expertos
                        </Typography>

                        <Typography className="card-subtitle text-center">
                            Para continuar elige un tipo de cita para poder contactarte con el teleasesor apropiado
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            <div className="flex justify-center mt-6 mb-4">
                <Card className="services-list">
                    <CardContent>
                        {servicesList.map((service, index) => (
                            [
                                <div
                                    key={`service_${index}`}
                                    className={`flex service-item pt-2 ${props.serviceSelected === service.title ? 'active' : ''}`}
                                    onClick={() => {
                                        if (props.onSelection) {
                                            props.onSelection(service.title, service.value)
                                        }
                                    }}
                                >
                                    <div className="list-image-container">
                                        <div className="list-image">
                                            <img
                                                src={service.image}
                                                alt={service.title}
                                                width="50px"
                                            />
                                        </div>
                                    </div>

                                    <div className="list-info">
                                        <p className="list-title">
                                            {service.title}
                                        </p>

                                        <p className="list-subtitle">
                                            {service.subtitle}
                                        </p>

                                        <p className="list-note">
                                            {service.note}
                                        </p>
                                    </div>
                                </div>,
                                (index !== servicesList.length - 1) ?
                                    <Divider
                                        key={`service_divider_${index}`}
                                        className="list-divider"
                                    />
                                    : null
                            ]
                        ))}
                    </CardContent>
                </Card>
            </div>

            <div className="flex justify-center">
                <Button
                    variant="contained"
                    className="service-button"
                    onClick={() => {
                        if (props.continueAction) {
                            props.continueAction()
                        }
                    }}
                >
                    AGENDAR
                </Button>
            </div>
        </div>
    </>;
}

export default Selection;