// react
import { useEffect } from 'react'
import {  Route, Routes, useLocation } from 'react-router-dom'

// style
import './App.scss'

// components
import CarAppointment from './components/Car/CarAppointment/CarAppointment'
import CarDetail from './components/Car/CarDetail/CarDetail'
import CarTouch from './components/Car/CarTouch/CarTouch'
import CarsForSale from './components/CarsForSale/CarsForSale'
import Services from './components/Services/Services'
import ConsignCar from './components/ConsignCar/ConsignCar'
import Home from './components/Home/Home'
import Privacy from './components/Privacy/Privacy'
import PagoDigital from './components/PagoDigital/PagoDigital'
import Footer from './components/shared/Footer/Footer'
import Header from './components/shared/Header/Header'
import FacebookPixel from 'react-facebook-pixel'
import { SellCarRoutes } from './components/SellCar/Routes/SellCarRoutes'
import { SuccessPayment } from './components/SuccessPayment/SuccessPayment'
// import HomeBlog from './components/Blog/HomeBlog/HomeBlog'
// import BlogContent from './components/Blog/BlogContent/BlogContent'
// import { PreguntasFrecuentesRoute } from './components/PreguntasFrecuentes/routes/PreguntasFrecuentesRoute'

// component
function App() {
    const location = useLocation();
    useEffect(() => {    
        window.onpageshow = function(event) {
            if (event.persisted) {
                window.location.reload()
            }
        }
     }, [])
     
    useEffect(() => {
        window.scrollTo(0, 0);
        FacebookPixel.init(7853265298099381);

        location.pathname && FacebookPixel.pageView({
            url: location.pathname,
          });
    }, [location.pathname]);
     
    return (
        <div className="App flex flex-col h-100 grow">
            <div className="grow">
                <div className="bg-black">
                    <Header />
                </div>
                {/* <FacebookMetaPixel/> */}
                <Routes>
                        {/* <Route path="*" element={<Navigate to="/"/>} /> */}
                        <Route path="/" element={<Home />} />
                        <Route path="/success" element={<SuccessPayment />} />
                        <Route path="compra-tu-auto" element={<CarsForSale />} />
                        <Route path="auto/:carName/:carYear/:carId" element={<CarDetail />} />
                        <Route path="auto/agendar-cita" element={<CarAppointment />} />
                        <Route path="auto/contactar" element={<CarTouch />} />
                        <Route path="servicios-automotrices" element={<Services />} />
                        <Route path="vende-tu-auto/*" element={<SellCarRoutes />} />
                        <Route path="consigna-tu-auto" element={<ConsignCar />} />
                        <Route path="politicas-de-privacidad" element={<Privacy />} />
                        <Route path="apartado-digital" element={<PagoDigital />} />
                        {/* <Route path="blog" element={<HomeBlog />} />
                        <Route path="/blog/:slug" element={<BlogContent />} />
                        <Route path="/blog/categorias/:slug" element={<HomeBlog />} />
                        <Route path="/preguntas-frecuentes/*" element={<PreguntasFrecuentesRoute />} /> */}
                </Routes>
            </div>
            <Footer />
        </div>
    )
}

// export
export default App
