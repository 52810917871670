// component
function Privacy() {
    // render component
    return <>
        <section id="privacy-section" className="p-4">
            <h1 className="title text-center font-bold mb-4">
                Aviso de Privacidad / Política de Privacidad
            </h1>
            
            <p className='mb-4'>
                <span className='font-bold'>Grupo Alerta, S.A. de C.V.</span> (en adelante, <span className='font-bold'>"NOWY”</span>), 
                con domicilio en Aq. Serdán Nte, No. 1507 Int. 3D, C.P. 82000, Col. Centro, Mazatlán, Sinaloa, 
                en cumplimiento a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, 
                es responsable de recabar datos personales de nuestros clientes y del personal que contrate, del tratamiento 
                que se le da a los mismos y de su protección. Por lo anterior, <span className='font-bold'>Nowy</span> ha establecido los siguientes 
                lineamientos para proteger dicha información:
            </p>

            <h2 className="title font-bold mb-4">
                1. Información Personal Solicitada
            </h2>
            
            <p className='mb-4'>
                <span className='font-bold'>Nowy</span> solicitará a sus clientes (en lo sucesivo los <span className='font-bold'>“titulares”</span>) 
                diversa información personal que será utilizada para la contratación de los servicios que presta, 
                así como para la contratación de personal, misma que consiste en:
            </p>

            <ol className='mb-4'>
                <li className='pl-[10px]'>
                    <span className='font-bold'>1.</span> Nombre Completo del Titular.
                </li>
                <li className='pl-[10px]'>
                    <span className='font-bold'>2.</span> Fecha de Nacimiento.
                </li>
                <li className='pl-[10px]'>
                    <span className='font-bold'>3.</span> Domicilio particular y/o fiscal
                </li>
                <li className='pl-[10px]'>
                    <span className='font-bold'>4.</span> Teléfonos de contacto.
                </li>
                <li className='pl-[10px]'>
                    <span className='font-bold'>5.</span> Correo electrónico.
                </li>
                <li className='pl-[10px]'>
                    <span className='font-bold'>6.</span> Registro Federal de Contribuyentes.
                </li>
                <li className='pl-[10px]'>
                    <span className='font-bold'>7.</span> Clave Única de Registro de Población (CURP).
                </li>
                <li className='pl-[10px]'>
                    <span className='font-bold'>8.</span> Datos financieros.
                </li>
                <li className='pl-[10px]'>
                    <span className='font-bold'>9.</span> Datos familiares (cónyuge).
                </li>
                <li className='pl-[10px]'>
                    <span className='font-bold'>10.</span> Estado Civil.
                </li>
            </ol>

            <p className='mb-4'>
                La información se solicita con el objeto de verificar y confirmar su identidad, 
                así como para proporcionar los servicios que contrata con <span className='font-bold'>Nowy</span>, 
                para cumplir con las obligaciones derivadas de los mismos; los cuales se encuentran debidamente detallados en la página de internet 
                www.---------- .mx ; así como para la elaboración de los contratos 
                correspondientes a dichos servicios y temas relativos a la facturación por su prestación; 
                en el caso de los empleados, para asuntos relacionados con su contratación.
            </p>
            
            <p className='mb-4'>
                En general, no existe obligación alguna de proveer ninguna información de carácter personal 
                para efectos de consultar nuestra página de internet. Sin embargo, Usted puede elegir contactarnos mediante 
                la forma que se contiene en la pestaña “Contacto” de nuestro sitio, caso en el que se solicitará 
                su nombre, correo electrónico y teléfono. Esta información será utilizada solamente para 
                contactarlo y ofrecerle la información solicitada. Es importante señalar que no compartiremos 
                su información personal con ningún tercero, con excepción de que dicha acción sea requerida por la ley.
            </p>

            <p className='mb-4'>
                El <span className='font-bold'>titular</span> manifiesta que desde que proporciona los datos requeridos, otorga su pleno consentimiento 
                libre y voluntario, hasta en tanto manifieste su oposición expresa para que <span className='font-bold'>Nowy</span>, los conserve, 
                resguarde y mantenga hasta en tanto los mismos se cancelen por haber cumplido con la finalidad 
                para la cual fueron requeridos, o por solicitud expresa del <span className='font-bold'>titular</span> en ejercicio de sus derechos.
            </p>

            <p className='mb-4'>
                Los datos sensibles que en su caso se llegasen a recabar serán tratados bajo las más estrictas medidas 
                de seguridad que garanticen su confidencialidad, por lo que le solicitaremos indique si acepta o 
                no el tratamiento de dichos datos:
            </p>

            <p className='mb-4'>
                <span className='font-bold'>
                    Consiento que mis datos personales sensibles sean tratados conforme a los términos y condiciones del presente aviso de privacidad.
                </span>

                <br />
                <br />

                Nombre y firma autógrafa del titular:
                <br />
                ______________________________

                <br />
                <br />

                Podrá enviar la copia debidamente firmada a la dirección de correo 
                electrónico <a className='text-sky-500'
                    href="mailto:angelo.tirado@alerta.com.mx"
                >
                    angelo.tirado@alerta.com.mx
                </a> a la atención del C. 
                Juan Angelo Martin Tirado Pazos.
            </p>


            <h2 className="title font-bold mb-4">
                2. Privacidad y Protección de Datos Personales
            </h2>

            <p className='mb-4'>
                <span className='font-bold'>Nowy</span> utiliza los procesos que están a su alcance, para la protección de los datos personales que resguarda. 
                Toda la documentación es resguardada en equipos de cómputo especializados protegidos por contraseña, 
                así como en archivos especiales, respecto de los cuales solo tiene acceso una persona destinada como encargada 
                para la protección de datos personales por el responsable y la Dirección General.
            </p>

            <p className='mb-4'>
                <span className='font-bold'>Nowy</span> establece y mantiene medidas de seguridad administrativas, técnicas y físicas que permiten proteger 
                los datos personales contra: daños, pérdida o alteración, destrucción, o el uso, acceso o tratamiento 
                no autorizado, así como también implementa medidas precautorias administrativas, técnicas y físicas 
                para resguardar los datos de los titulares de pérdidas, robos, o uso indebido, así como del acceso, 
                la divulgación, modificación o destrucción no autorizados.
            </p>
                
            <h2 className="title font-bold mb-4">
                3. Transferencia de los datos
            </h2>
            
            <p className='mb-4'>
                <span className='font-bold'>Nowy</span> con la autorización otorgada por el <span className='font-bold'>titular</span> de los datos personales podrá realizar transferencia 
                de datos personales recabados de sus clientes a compañías pertenecientes a este grupo empresarial con 
                la autorización otorgada por el titular, para el ofrecimiento de servicios con el objetivo de prestarle 
                una mejor atención, en el entendido de que las empresas pertenecientes al Grupo podrán contactarlo directamente. 
                Así mismo <span className='font-bold'>Nowy</span> podrá comunicar sus datos personales y/o sensibles atendiendo requerimientos de información de las 
                autoridades competentes.
            </p>
            
            <p className='mb-4'>
                Hasta en tanto el <span className='font-bold'>titular</span> manifieste su oposición a la transferencia de sus datos personales cumpliendo 
                con los requisitos que marca este aviso de privacidad, se entenderá que consiente en que <span className='font-bold'>Nowy</span> 
                los transfiera única y exclusivamente en los términos indicados en el presente aviso de privacidad.
            </p>
            
            <p className='mb-4'>
                <span className='font-bold'>
                    No autorizo la transferencia de mis datos personales a terceros.
                </span>
                
                <br />
                <br />

                Nombre y firma autógrafa del titular:
                <br />
                ______________________________

                <br />
                <br />

                Podrá enviar la copia debidamente firmada del presente aviso de privacidad con su 
                negativa de autorización a la dirección de correo electrónico <a className='text-sky-500'
                    href="mailto:angelo.tirado@alerta.com.mx"
                >
                    angelo.tirado@alerta.com.mx
                </a> a 
                la atención del C. Juan Angelo Martin Tirado Pazos, o bien, de forma impresa al domicilio 
                de <span className='font-bold'>Nowy</span> precisado en el presente aviso de privacidad.
            </p>
            
            <h2 className="title font-bold mb-4">
                4. Responsabilidad sobre el uso de la Información.
            </h2>
            
            <span className='font-bold'>Nowy</span> podrá revelar sus datos fiscales, incluyendo la información financiera proporcionada por el titular, 
            única y exclusivamente cuando sea útil para cumplir con la ley, para cooperar con o buscar asistencia por 
            parte de autoridades de seguridad pública o para participar conjuntamente con otras entidades en actividades 
            contra el fraude o en cualquiera de los supuestos previstos en el artículo 10 de la LFPDPPP.
            
            <h2 className="title font-bold mb-4">
                5. Uso de "cookies" y WebBeacons
            </h2>

            <p className='mb-4'>
                <span className='font-bold'>Nowy</span> hace uso de herramientas que permiten obtener información de forma pasiva del <span className='font-bold'>titular</span> 
                como cookies y web beacons y como una herramienta de uso común para registrar el comportamiento 
                de navegación en línea (tracking).
            </p>
            
            <p className='mb-4'>
                Las cookies son archivos de texto que son descargados automáticamente y almacenados en el 
                navegador y/o disco duro del equipo de cómputo del <span className='font-bold'>titular</span> al navegar en un sitio de 
                internet y que permiten al sitio recordar algunos datos del titular, entre ellos, 
                sus preferencias para la visualización de las páginas en ese servidor.
            </p>
            
            <p className='mb-4'>
                Por su parte, las web beacons son imágenes insertadas en una página de internet o 
                correo electrónico, que pueden ser utilizadas para monitorear el comportamiento de 
                un visitante, como almacenar información sobre la dirección IP del usuario, 
                duración del tiempo de interacción en dicha página y el tipo de navegador utilizado, entre otros.
            </p>

            <p className='mb-4'>
                Le informamos que utilizamos cookies y web beacons para obtener información personal de usted, como la siguiente:
            </p>

            <ul className='mb-4'>
                <li className='pl-[10px]'>
                    <span className='font-bold'>•</span> Tipo de navegador
                </li>
                <li className='pl-[10px]'>
                    <span className='font-bold'>•</span> La información a la que accede en nuestro sitio de internet.
                </li>
                <li className='pl-[10px]'>
                    <span className='font-bold'>•</span> La dirección IP.
                </li>
            </ul>

            <p className='mb-4'>
                Estas cookies y otras tecnologías pueden ser deshabilitadas. Para conocer cómo hacerlo, 
                consulte el siguiente vínculo o dirección electrónica

                <br />
                <a className='text-sky-500'
                    href="http://www.interproteccion.com.mx/avisosdeprivacidad/cookies-webbeacons/Deshabilitarcookiesywebbeacons.pdf"
                >
                    http://www.interproteccion.com.mx/avisosdeprivacidad/cookies-webbeacons/Deshabilitarcookiesywebbeacons.pdf
                </a>
                
                <br />
                <a className='text-sky-500'
                    href="http://www.macromedia.com/support/documentation/es/flashplayer/help/settings_manager07.html"
                >
                    http://www.macromedia.com/support/documentation/es/flashplayer/help/settings_manager07.html
                </a>
            </p>
            
            <h2 className="title font-bold mb-4">
                6. Medios y procedimiento para la actualización del aviso de privacidad.
            </h2>
            
            <p className='mb-4'>
                <span className='font-bold'>Nowy</span> se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al 
                presente aviso de privacidad, para la atención de novedades legislativas, políticas internas de 
                la empresa o nuevos requerimientos para la prestación u ofrecimiento de los productos y servicios que ofrece.
            </p>
            
            <p className='mb-4'>
                Estas modificaciones estarán disponibles a través de los siguientes medios: (i) anuncios visibles 
                en nuestra página de Internet o Facebook o (ii) mediante comunicado enviado al último correo 
                electrónico que nos haya proporcionado, o en su caso, al domicilio que nos haya proporcionado.
            </p>
            
            <h2 className="title font-bold mb-4">
                7. Mecanismos para ejercer los derechos vinculados a la protección de datos personales (derechos ARCO).
            </h2>
            
            <p className='mb-4'>
                Usted tiene derecho a acceder a los datos personales que poseemos y los 
                detalles del tratamiento de los mismos, así como a rectificarlos en caso de 
                ser inexactos o incompletos; cancelarlos cuando considere que no se requieren 
                para alguna de las finalidades señaladas en el presente aviso de privacidad, estén 
                siendo utilizados para finalidades no consentidas o haya finalizado la relación de 
                servicios o contractual, o bien, oponerse al tratamiento de los mismos para fines específicos.
            </p>
            
            <p className='mb-4'>
                A fin de brindar al <span className='font-bold'>titular</span> el medio para ejercer los derechos de Acceso, 
                Rectificación, Cancelación u Oposición 
                (conocidos como Derechos ARCO), <span className='font-bold'>Nowy</span> en su carácter de responsable pone a 
                disposición de los titulares la dirección de correo electrónico: <a className='text-sky-500'
                    href="mailto:angelo.tirado@alerta.com.mx"
                >
                    angelo.tirado@alerta.com.mx
                </a> a través de la cual se dará trámite y seguimiento a las solicitudes de acceso, rectificación, 
                cancelación u oposición, designando como 
                encargada para tales efectos al C. Juan Angelo Martin Tirado Pazos, en términos de lo establecido en el 
                artículo 30 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
            </p>

            <p className='mb-4'>
                El <span className='font-bold'>titular</span> deberá enviar a través del correo electrónico antes mencionado a la atención de la encargada, 
                una solicitud para el acceso, rectificación, cancelación u oposición respecto de sus datos personales y 
                deberá confirmar que <span className='font-bold'>Nowy</span> haya recibido su solicitud al número telefónico 9- 83-27-22.
            </p>

            <p className='mb-4'>
                Dicha solicitud deberá contener:
            </p>

            <ul className='mb-4'>
                <li className='pl-[10px]'>
                    - El nombre completo del <span className='font-bold'>titular</span> y todos los datos que le fueron solicitados para 
                    registrarse así como un correo electrónico para comunicarle la respuesta a su solicitud.
                </li>
                <li className='pl-[10px]'>
                    - Copia electrónica de los documentos que acrediten la identidad del <span className='font-bold'>titular</span> de los datos personales (RFC, INE).
                </li>
                <li className='pl-[10px]'>
                    - En caso de que el <span className='font-bold'>titular</span> comparezca por representante, deberá exhibir 
                    por la misma vía, una carta poder simple firmada por el titular, su apoderado 
                    y dos testigos, adjuntando copia de las identificaciones del apoderado y dos testigos.
                </li>
                <li className='pl-[10px]'>
                    - Descripción clara y precisa de los datos personales respecto de los que 
                    se busca ejercer alguno de los derechos antes mencionados.
                </li>
                <li className='pl-[10px]'>
                    - Cualquier otro elemento o documento que facilite la localización de los datos personales.
                </li>
                <li className='pl-[10px]'>
                    - Indicar las modificaciones a realizarse y/o las limitaciones al uso de los 
                    datos personales y aportar la documentación que sustente su petición.
                </li>
            </ul>
        
            <p className='mb-4'>
                <span className='font-bold'>Nowy</span> comunicará al <span className='font-bold'>titular</span> de los datos personales la determinación adoptada, en un plazo no mayor 
                a 20 días hábiles contados desde la fecha en que se recibió la solicitud. Este plazo 
                podrá ser ampliado por <span className='font-bold'>Nowy</span> en una sola ocasión por un periodo igual, siempre y cuando así 
                lo justifiquen las circunstancias del caso. <span className='font-bold'>Nowy</span> informará al <span className='font-bold'>titular</span> de los datos personales 
                el sentido y motivación de la resolución por correo electrónico y acompañará dicha resolución de las pruebas pertinentes, en su caso.
            </p>

            <p className='mb-4'>
                <span className='font-bold'>Nowy</span> podrá negar el acceso total o parcial a los datos personales o a 
                la realización de la rectificación, cancelación u oposición al tratamiento de los mismos, en los siguientes supuestos:
            </p>

            <ul className='mb-4'>
                <li className='pl-[10px]'>
                    - Cuando el solicitante no sea el <span className='font-bold'>titular</span> o el representante legal no esté acreditado para ello.
                </li>
                <li className='pl-[10px]'>
                    - Cuando en la base de datos de <span className='font-bold'>Nowy</span> no se encuentren los datos personales del solicitante. 
                </li>
                <li className='pl-[10px]'>
                    - Cuando se lesionen los derechos de un tercero.
                </li>
                <li className='pl-[10px]'>
                    - Cuando exista impedimento legal o resolución de una autoridad.
                </li>
                <li className='pl-[10px]'>
                    - Cuando Nowy, tenga alguna acción legal en contra del titular.
                </li>
                <li className='pl-[10px]'>
                    - Cuando la rectificación, cancelación u oposición haya sido previamente realizada, 
                    de manera que la solicitud carezca de materia.
                </li>
            </ul>
            
            <p className='mb-4'>
                Una vez cancelados los datos personales correspondientes, <span className='font-bold'>Nowy</span> dará aviso a su titular.
            </p>

            <p className='mb-4'>
                <span className='font-bold'>Nowy</span> no estará obligada a cancelar los datos personales cuando se trate de los supuestos 
                establecidos en el artículo 26 de la Ley Federal de Protección de Datos en Posesión de los Particulares.
            </p>

            <p className='mb-4'>
                Asimismo, cuando la información recabada en los datos personales deje de ser necesaria para el 
                cumplimiento de las finalidades previstas en este Aviso de Privacidad y en las disposiciones 
                jurídicas aplicables, sus datos personales podrán ser cancelados de la base de datos de <span className='font-bold'>Nowy</span> al 
                llevar a cabo los procesos de depuración correspondientes.
            </p>
            
            <h2 className="title font-bold mb-4">
                8. Quejas y denuncias
            </h2>
            
            <p className='mb-4'>
                Si usted considera que sus derechos de protección de datos personales han sido lesionados 
                por alguna de nuestras actuaciones o respuestas a sus solicitudes, presume que en el 
                tratamiento de sus datos personales existe alguna violación a las disposiciones de la Ley de 
                Protección de Datos Personales en Posesión de los Particulares y/o su Reglamento, podrá interponer 
                la queja o denuncia correspondiente ante el Instituto Nacional de Transparencia, Acceso a la Información y 
                Protección de Datos (INAI). Para mayor información visite <a className='text-sky-500' 
                    href="http://inicio.inai.org.mx/SitePages/ifai.aspx"
                >
                    http://inicio.inai.org.mx/SitePages/ifai.aspx
                </a>.
            </p>
        </section>
    </>
}

export default Privacy
