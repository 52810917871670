import axios from 'axios'

const send = ({
    name,
    phone,
    email,
    inventory_id,
}) => {
    return new Promise((resolve) => {
        axios.post('api/buy-your-car/contact', {
            name,
            phone,
            email,
            inventory_id,
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => { })
    })
}

const BuyService = {
    send,
}

export default BuyService
