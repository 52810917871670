
export const SpecsCarDetail = ({car}) => {
  return (
    <div className="mt-16">
                        <div className="text-3xl w-full text-center font-semibold text-gray-800 mb-8">
                            ESPECIFICACIONES
                        </div>

                        {(car?.car_location || car?.availability) ? <div className="mb-10">
                            <div className="font-bold text-2xl pb-2">Entrega</div>
                            <div>
                                <div className="grid grid-cols-1 md:grid-cols-3">
                                    {car?.car_location ? <div>
                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Ubicación del Auto
                                            </div>
                                            <div>
                                                {car?.car_location}
                                            </div>
                                        </div>
                                    </div> : null}

                                    {car?.availability ? <div>
                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Método de Entrega
                                            </div>
                                            <div>
                                                {car?.availability || '---'}
                                            </div>
                                        </div>
                                    </div> : null}
                                </div>
                            </div>
                        </div> : null}

                        <div className="mb-10">
                            <div className="font-bold text-2xl pb-2">General</div>
                            <div>
                                <div className="grid grid-cols-1 md:grid-cols-3">
                                    <div>
                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Ubicación del Auto
                                            </div>
                                            <div>
                                                {car?.car_location || '---'}
                                            </div>
                                        </div>

                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Pasajeros
                                            </div>
                                            <div>
                                                {car?.catalog?.passengers_number || '---'}
                                            </div>
                                        </div>
                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Color
                                            </div>
                                            <div>
                                                {car?.color?.name || '---'}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Transmisión
                                            </div>
                                            <div>
                                                {car?.catalog?.transmission?.name || '---'}
                                            </div>
                                        </div>
                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Tracción
                                            </div>
                                            <div>
                                                {car?.catalog?.traction?.name || '---'}
                                            </div>
                                        </div>
                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Número VIN
                                            </div>
                                            <div>
                                                {car?.vin_number || '---'}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Combustible
                                            </div>
                                            <div>
                                                {car?.catalog?.fuel?.name || '---'}
                                            </div>
                                        </div>
                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Aire acondicionado
                                            </div>
                                            <div>
                                                {car?.catalog?.air_conditioning?.name || '---'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-10">
                            <div className="font-bold text-2xl pb-2">Exterior</div>
                            <div>
                                <div className="grid grid-cols-1 md:grid-cols-3">

                                    <div>
                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Faros
                                            </div>
                                            <div>
                                                {car?.catalog?.headlights?.name || '---'}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Material rin
                                            </div>
                                            <div>
                                                {car?.catalog?.rim_material?.name || '---'}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex border-b mx-4 flex-row py-2">
                                            <div className="font-bold grow text-emerald-600">
                                                Numero rin
                                            </div>
                                            <div>
                                                {car?.catalog?.rims_size || '---'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
  )
}
